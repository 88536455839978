import { useStyles } from '@archipro-design/aria';
import * as S from './CartItemList.style';
import CartItem from './CartItem';
import type { CartItem as CartItemType } from '../../types';

export interface Props {
    items: CartItemType[];
}

const CartItemList = ({ items }: Props) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles();
    return (
        <ul className={css(S.CartItemList)}>
            {items.length ? (
                items.map(item => <CartItem key={item.ID} item={item} />)
            ) : (
                <li className={css(S.EmptyList)}>Shopping cart is empty </li>
            )}
        </ul>
    );
};

export default CartItemList;
