import { useRef } from 'react';
import {
    AppDisplayModeType,
    Badge,
    Popup,
    TopNavigationProps,
    useStyles,
} from '@archipro-design/aria';
import { Cart } from '../types';
import { CustomShoppingCart } from '@archipro-design/icons';
import ShoppingCartPopup from '../components/shopping-cart-popup/ShoppingCartPopup';
import { useDebounceFn } from 'ahooks';
import * as S from './svgIconSlotOverride.style';

export interface CartSlotPopupState {
    open: boolean;
    onChange: (state: boolean) => void;
}

export function useCartSlot(
    { desktop }: AppDisplayModeType,
    count: number,
    popupState: CartSlotPopupState,
    cart?: Cart | null,
    shopNavEnabled?: boolean
): TopNavigationProps['cartSlot'] | null {
    const { css } = useStyles();
    const { open: cartPopupOpen, onChange: onPopupChange } = popupState;

    const cartButtonRef = useRef<HTMLDivElement>();

    const { run: openCart, cancel } = useDebounceFn(
        () => {
            onPopupChange(true);
        },
        {
            wait: 200,
        }
    );

    if (!cart) return null;

    // Skip if shopNav is OFF and cart is empty
    if (!shopNavEnabled && count === 0) {
        return null;
    }

    const badge = (
        <a href={'/shopping-cart'} style={{ color: 'inherit' }}>
            <Badge count={count} anchorVertical="top">
                <CustomShoppingCart
                    aria-label={'cart'}
                    className={css(S.svgIconOverride)}
                />
            </Badge>
        </a>
    );

    return {
        key: 'cartSlot',
        ref: cartButtonRef,
        icon: !desktop ? (
            badge
        ) : (
            <Popup
                position={'below'}
                align={'end'}
                onOpenChange={(_, props) => {
                    if (props && props.open) {
                        openCart();
                    } else {
                        cancel();
                        onPopupChange(false);
                    }
                }}
                open={cartPopupOpen}
                trigger={badge}
                content={{
                    content: <ShoppingCartPopup cart={cart} />,
                }}
                target={cartButtonRef.current}
                on={['context', 'hover']}
                offset={[141, 5]}
                mouseLeaveDelay={50}
            />
        ),
    };
}
