import { useCallback } from 'react';
import { Tracker } from '../../types';
import {
    DOWNLOAD_ATTR_PROFESSIONAL_TITLE,
    DOWNLOAD_ATTR_FILE_TITLE,
} from '../../constants';

export const useTrackDownloadClick = (trackerInstance: Tracker) => {
    return useCallback(
        (target: HTMLElement, mouseButton: 0 | 1 | 2) => {
            const professionalTitle = target.getAttribute(
                DOWNLOAD_ATTR_PROFESSIONAL_TITLE
            );
            const fileTitle = target.getAttribute(DOWNLOAD_ATTR_FILE_TITLE);

            if (!professionalTitle) {
                console.warn(
                    `Missing attribute: ${DOWNLOAD_ATTR_PROFESSIONAL_TITLE}. Tracking performance will be degraded.`
                );
                return;
            }
            if (!fileTitle) {
                console.warn(
                    `Missing attribute: ${DOWNLOAD_ATTR_FILE_TITLE}. Tracking performance will be degraded.`
                );
                return;
            }

            trackerInstance
                .log('DownloadsClick', {
                    url: new URL(window.location.href),
                    label: professionalTitle,
                    data: {
                        ExtraData: {
                            fileTitle,
                            MouseButton: mouseButton,
                        },
                    },
                })
                .then(() => {})
                .catch(() => {});
        },
        [trackerInstance]
    );
};
