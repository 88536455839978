import {
    Badge,
    StyleRule,
    TopNavigationProps,
    useStyles,
} from '@archipro-design/aria';
import { RenderAnchorTag } from '../types';
import { InboxCustom } from '@archipro-design/icons';
export function useInboxSlot(
    count = 0,
    renderAnchorTag: RenderAnchorTag
): TopNavigationProps['cartSlot'] {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles();
    return {
        key: 'inboxSlot',
        icon: (
            <Badge
                count={count}
                anchorVertical="top"
                anchorHorizontal="right"
                size={'md'}
                className={css(InboxBadge)}
            >
                <InboxCustom aria-label={'favourite'} />
            </Badge>
        ),
        ...renderAnchorTag('/member/inbox'),
    };
}

const InboxBadge: StyleRule = ({ theme }) => ({
    [`& > div`]: {
        transform: `scale(1) translateX(65%) translateY(-66%)`,
        [theme.screen.laptop]: {
            transform: `scale(1) translateX(50%) translateY(-50%)`,
        },
    },
});
