import React from 'react';
import {
    AppDisplayModeType,
    BadgeProps,
    ObjectShorthandValue,
    PersonalMenuProps,
    ProfileMenuItemProps,
    pxToRem,
    ShorthandValue,
} from '@archipro-design/aria';

import {
    Notifications,
    PermissionEnum,
    RenderAnchorTag,
    TopNavFeatureFlags,
    User,
} from '../types';
import { isLoggedInUser } from './isLoggedIn';
import { SignOutLight } from '@archipro-design/icons';
import { TOGGLE_ADD_PROFESSIONAL_URL } from './makeRenderAnchorTagFn';

export const PERSONAL_MENU_ITEMS = [
    {
        label: 'Shopping cart',
        link: '/shopping-cart',
    },
    {
        label: 'Inbox',
        link: '/member/inbox',
    },
    {
        label: 'Design Boards',
        link: '/member/design-boards',
    },
    {
        label: 'Notifications',
        link: '/member/notifications',
    },
    {
        label: 'Settings',
        link: '/member/settings',
    },
] as const;

function getBadgeProps(
    item: (typeof PERSONAL_MENU_ITEMS)[number],
    notifications: Notifications
): ShorthandValue<BadgeProps> {
    switch (item.label) {
        case 'Notifications':
            return notifications.website;
        case 'Design Boards':
            return notifications.designBoard;
        case 'Inbox':
            return notifications.userInbox?.count;
        default:
            return null;
    }
}

export const createPersonalMenuProps = (opts: {
    renderAnchorTag: RenderAnchorTag;
    onClosePopup: () => void;
    user?: User;
    canCreateProfessional: boolean;
    notifications?: Notifications | null;
    appDisplayMode: AppDisplayModeType;
    featureFlags?: TopNavFeatureFlags;
}): PersonalMenuProps['personalProfileMenu'] | null => {
    const {
        renderAnchorTag,
        user,
        notifications,
        onClosePopup,
        canCreateProfessional,
        appDisplayMode,
        featureFlags,
    } = opts;
    if (!user || !isLoggedInUser(user)) return null;

    const personalProfileMenuItems: ProfileMenuItemProps[] =
        PERSONAL_MENU_ITEMS.filter(i => {
            // Disable for AU, shop not enabled yet, no suppliers
            if (
                (!featureFlags?.shopNavEnabled ||
                    !featureFlags?.ecommerceActiveEnabled) &&
                i.link === '/shopping-cart'
            ) {
                return false;
            }
            return true;
        }).map(item => {
            const { label, link } = item;
            return {
                key: label,
                label,
                badge: notifications
                    ? getBadgeProps(item, notifications)
                    : undefined,

                ...renderAnchorTag(link),
            };
        });

    if (canCreateProfessional && !appDisplayMode.mobile) {
        personalProfileMenuItems.push({
            label: 'Add Professional',
            onClick: () => {
                onClosePopup();
            },
            ...renderAnchorTag(TOGGLE_ADD_PROFESSIONAL_URL),
        });
    }
    if (
        appDisplayMode.desktop &&
        user.IsArchiproStaff &&
        user.Permissions.includes(PermissionEnum.BmContentApprove)
    ) {
        personalProfileMenuItems.push({
            label: 'Content Hub',
            link: '/ap-admin/content/projects',
        });
    }

    if (
        featureFlags &&
        featureFlags.ecommerceEnabled &&
        featureFlags.ecommerceActiveEnabled
    ) {
        personalProfileMenuItems.splice(2, 0, {
            label: 'Orders',
            onClick: () => {
                onClosePopup();
            },
            badge: user.OrderNotificationCount || undefined,
            ...renderAnchorTag('/member/orders'),
        });
    }

    if (featureFlags) {
        user.FeatureFlags.filter(flag => flag.CanToggle).forEach(flag => {
            personalProfileMenuItems.push({
                label: (flag.Enabled ? 'Disable' : 'Enable') + ' ' + flag.Title,
                onClick: () => {
                    fetch(flag.ToggleArgs)
                        .then(() => {
                            if (typeof window !== 'undefined') {
                                window.location.reload();
                            }
                        })
                        .catch(err => {
                            console.error(err);
                        });
                },
            });
        });
    }

    return {
        menuItems: personalProfileMenuItems,
        additionalItems: [
            {
                label: 'List a Business',
                link: '/advertising',
            },
        ],
        ...(appDisplayMode.desktop && {
            eventItems: [
                {
                    label: 'Home Design Evening',
                    link: '/home-design-event',
                },
            ],
        }),
        logout: {
            icon: appDisplayMode.desktop ? (
                <SignOutLight size="1x" variables={{ '1xSize': pxToRem(20) }} />
            ) : undefined,
            label: 'Log out',
            ...renderAnchorTag('/logout'),
        } as unknown as ObjectShorthandValue<ProfileMenuItemProps>,
    };
};
