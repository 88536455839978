import type { Logger } from '@archipro-website/logger';
import type {
    AdvertCreative,
    AdvertPlacementType,
    AdvertRequest,
    ItemData,
} from '../type';
import {
    extractAdvertRequestUrlContext,
    getAdvertUrlParams,
} from '../utils/utils';
import { parseURL } from '@archipro-website/top-navigation';

type FetchFunction = (url: string, init?: RequestInit) => Promise<Response>;

interface ClientFetchAdOption {
    requestUrl: string;
    placementType: AdvertPlacementType;
    logger: Logger;
    addPathContext?: string[];
    item?: ItemData;
}

export const adRequestClient = async (
    opts: ClientFetchAdOption
): Promise<AdvertCreative[]> => {
    const { requestUrl, placementType, logger, item, addPathContext } = opts;
    const url = new URL(requestUrl);
    const { basePath } = parseURL(url.pathname);

    const { setId, forceAdvertCreativeId, resetAdvertCache } =
        getAdvertUrlParams(url);

    const pathContext = extractAdvertRequestUrlContext(
        url,
        addPathContext ?? []
    );

    // Member ID can be set to something when ads are targeted per user. Otherwise, ads will be reloaded per users
    return fetchAd(
        fetch,
        logger,
        placementType,
        basePath,
        url.href,
        setId,
        forceAdvertCreativeId,
        resetAdvertCache,
        pathContext,
        item
    );
};

export const fetchAd = async (
    fetch: FetchFunction,
    logger: Logger,
    placementType: AdvertPlacementType,
    pageUrl: string,
    pageHref: string,
    setId: number,
    forceAdvertCreativeId: number | null,
    resetAdvertCache: string,
    pathContext: Record<string, string>,
    item?: ItemData
): Promise<AdvertCreative[]> => {
    const body: AdvertRequest = {
        placement: placementType,
        url: pageUrl,
        refresh: pageHref,
        setId: setId,
        item,
    };

    if (forceAdvertCreativeId) {
        body.forceAdvertCreativeId = forceAdvertCreativeId;
    }

    if (resetAdvertCache) {
        body.resetAdvertCache = resetAdvertCache;
    }

    if (Object.keys(pathContext).length !== 0) {
        body.pathContext = pathContext;
    }

    return fetch(`/api/advert/v1/request_ad`, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then((response) => response.json())
        .catch((e) => {
            logger.error('Error fetching ad: ', e);
            return [];
        });
};
