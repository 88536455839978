import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';

export const Base: StyleRule = ({ theme }) => ({
    position: 'fixed',
    right: pxToRem(24),
    bottom: pxToRem(90),
    zIndex: theme.siteVariables.zIndexes.overlayPriority,
    [theme.screen.min.tablet]: {
        right: pxToRem(48),
        bottom: pxToRem(48),
    },
    boxShadow: `0rem 4rem 24rem ${theme.siteVariables.colors.transparency.black['20%']}`,
});

export const Body: StyleRule = ({ theme }) => ({
    width: `calc(100vw - ${pxToRem(48)})`,
    padding: pxToRem(28),
    backgroundColor: theme.siteVariables.colors.primitive.white,
    [theme.screen.min.tablet]: {
        width: pxToRem(450),
    },
});

export const ModalTitle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Medium65,
    fontSize: pxToRem(24),
    color: theme.siteVariables.colors.charcoal['000'],
    lineHeight: theme.siteVariables.lineHeightDefault,
    marginBottom: pxToRem(24),
    letterSpacing: theme.siteVariables.letterSpacingSmall,
});

export const ModalText: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    lineHeight: '150%',
    fontSize: pxToRem(16),
    color: theme.siteVariables.colors.charcoal[200],
    marginBottom: pxToRem(40),
});

export const Link: StyleRule = () => ({
    textDecoration: 'underline',
    cursor: 'pointer',
});

export const NoWrap: StyleRule = () => ({
    whiteSpace: 'nowrap',
});

export const Buttons: StyleRule = ({ theme }) => ({
    display: 'flex',
    [theme.screen.min.tablet]: {
        '> button': {
            padding: pxArrayToRem([14, 38]),
        },
    },
    '> button': {
        padding: pxArrayToRem([20, 23]),
    },
});
