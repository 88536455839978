import { useCountUp } from 'react-countup';
import { useInViewport } from 'ahooks';
import { useEffect, useState, useRef } from 'react';
import { Flex, Caption, Button, useStyles } from '@archipro-design/aria';
import { isUserLoggedIn, useUser } from '@modules/user';
import { useAuth } from '@modules/root/context';
import { CustomArrowRight } from '@archipro-design/icons';
import * as S from './MonthlyUserCounter.style';

interface MonthlyUserCounterProps {
    count: number;
}

interface ShortNumberProps {
    amount: number;
    unit: string;
}

const FOOTER_COUNTER_TEXT = [
    'Monthly ArchiPro users',
    'Kickstart your building project today',
];

export const FOOTER_COUNTER_MINIMUM_THRESHOLD = 0; //do not show counter if falls below this number of users.

const convertNumberToShort = (number: number): ShortNumberProps => {
    if (number >= 1e6) {
        return { amount: Math.floor(number / 1e6), unit: 'M+' };
    } else if (number >= 1e3) {
        return { amount: Math.floor(number / 1e3), unit: 'K' };
    } else {
        return { amount: number, unit: '' };
    }
};

const MonthlyUserCounter = ({ count }: MonthlyUserCounterProps) => {
    const { css } = useStyles();
    const [counted, setCounted] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const user = useUser();
    const isLoggedIn = isUserLoggedIn(user);
    const { showAuthModal } = useAuth();

    const [inViewport] = useInViewport(ref, {
        threshold: 0.5,
    });

    const converted = convertNumberToShort(count);

    const { countUp, start } = useCountUp({
        start: 0,
        end: converted.amount,
    });

    useEffect(() => {
        if (inViewport && !counted && start) {
            start();
            setCounted(true);
        }
    }, [inViewport, counted, start]);

    return (
        <div ref={ref} className={css(S.MonthlyUserCounterContainer)}>
            <div className={css(S.Counter)}>
                {countUp}
                {converted.unit}
            </div>
            <Flex className={css(S.CaptionHolder)}>
                <Flex column>
                    {FOOTER_COUNTER_TEXT.map((text, i) => (
                        <Caption
                            className={css(S.CounterCaption)}
                            key={`footer-multi-line-${i}`}
                        >
                            {text}
                        </Caption>
                    ))}
                </Flex>
                {!isLoggedIn && (
                    <Button
                        className={css(S.Signup)}
                        onClick={() => showAuthModal({ authSource: 'footer' })}
                    >
                        Sign up
                        <CustomArrowRight />
                    </Button>
                )}
            </Flex>
        </div>
    );
};

export default MonthlyUserCounter;
