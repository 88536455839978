import { ComponentType, ForwardRefExoticComponent } from 'react';
import { RenderAnchorTag } from '../types';

/**
 * Routes that belong to eb (packages/web)
 */
export const MODAL_KEYS = {
    NEW_PROFESSIONAL: 'new_professional',
    LOGIN: 'login',
} as const;

export const TOGGLE_ADD_PROFESSIONAL_URL = `?${MODAL_KEYS.NEW_PROFESSIONAL}=true`;

type Project = 'web' | 'manager';
interface MakeRenderAnchorTagConfig {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    linkComponent: ComponentType | ForwardRefExoticComponent<any>;
    /**
     * Handle routes outside the router.
     * Return false to avoid handling
     */
    handle?: (
        ...args: Parameters<RenderAnchorTag>
    ) => ReturnType<RenderAnchorTag> | false;
}
/**
 * Routes some are just present in web project, but some can be present in both project (manager and web)
 * listed as Regex expressions
 */
const projectRoutes = (remixEnabled?: boolean): Record<string, Project> => ({
    '/ecommerce-launch': 'web',
    '/search': remixEnabled ? 'web' : 'manager',
    '/project': remixEnabled ? 'web' : 'manager',
    '/product': remixEnabled ? 'web' : 'manager',
    '/professional': remixEnabled ? 'web' : 'manager',
    '/article': remixEnabled ? 'web' : 'manager',
    '/archizeen': remixEnabled ? 'web' : 'manager',
    '/digital-magazine': remixEnabled ? 'web' : 'manager',
    '/welcome': remixEnabled ? 'web' : 'manager',
});

const findRouteOwner = (path: string, remixEnabled?: boolean): Project => {
    const routes = projectRoutes(remixEnabled);
    const matched = Object.keys(routes).find(route => path.startsWith(route));
    const project = matched && routes[matched];
    if (project) {
        return project;
    }
    return 'manager'; //anything that we don't know goes to manager;
};

export const makeRenderAnchorTagFn = (
    project: Project,
    config: MakeRenderAnchorTagConfig,
    remixEnabled?: boolean
): RenderAnchorTag => {
    const { linkComponent: Link, handle } = config;

    return pathname => {
        const handled = handle?.(pathname);
        if (handled) return handled;

        const owner = findRouteOwner(pathname, remixEnabled);
        const isRoot = pathname === '/' || pathname.startsWith('?'); // Handle root with query params

        const isExternalLink = pathname.startsWith('http');
        // Homepage can't be handled easily by WEB_ROUTES
        if (isRoot && (project === 'manager' || !remixEnabled)) {
            return {
                as: 'a',
                href: pathname,
            };
        }

        // force page load for manager routes when remix is enable
        if (project === 'manager' && remixEnabled) {
            return {
                as: 'a',
                href: pathname,
            };
        }

        /**
         *
         * It's much easier checking against only the web routes, since it's a
         * significantly smaller list to maintain
         */
        if (isRoot || (project === owner && !isExternalLink)) {
            return {
                as: Link,
                to: pathname,
            };
        }

        return {
            as: 'a',
            href: pathname,
            ...(isExternalLink && {
                rel: 'noFollow',
                target: '_blank',
            }),
        };
    };
};
