import { useCountryContext } from '@archipro-website/localisation';
import type { Directory } from '@archipro-website/top-navigation';
import { useAppData } from '@modules/root';

interface UseSecondaryNavOpts {
    directory?: Directory;
    skip?: boolean;
}

export const useSecondaryNavData = (props: UseSecondaryNavOpts) => {
    const { directory = 'product', skip } = props;
    const appData = useAppData();
    const { code } = useCountryContext();

    if (skip) return { data: [] };

    const data = appData.megamenu?.[directory].subNavItems ?? [];
    const additionalItems = code === 'NZ' ? getAdditionalItems(directory) : [];

    return { data: [...data, ...additionalItems] };
};

const getAdditionalItems = (directory: Directory) => {
    switch (directory) {
        default:
            return [];
    }
};
