import React from 'react';
import * as S from './BackgroundOverlay.style';
import {
    Logo,
    useAppDisplayModeContext,
    useStyles,
    useTheme,
} from '@archipro-design/aria';
import { Link } from '@remix-run/react';
import FooterLinks from '~/modules/error/component/page-403/FooterLinks';

const BackgroundOverlay: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => {
    const { css } = useStyles();
    const theme = useTheme();
    const { desktop } = useAppDisplayModeContext();

    return (
        <div className={css(S.BackgroundContainer)}>
            {desktop && (
                <div className={css(S.Logo)}>
                    <Logo
                        showFull
                        variables={{
                            logoSize: {
                                height: 49.81,
                                width: 240,
                            },
                            logoPrimaryColor:
                                theme.siteVariables.colors.white['FFF'],
                        }}
                        as={Link}
                        to="/"
                    />
                </div>
            )}

            <div className={css(S.MainContent)}>{children}</div>

            {desktop && (
                <div className={css(S.Footer)}>
                    <FooterLinks />
                </div>
            )}

            {desktop && <div className={css(S.BottomPart)}> </div>}
        </div>
    );
};

export default BackgroundOverlay;
