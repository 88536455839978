import {
    Badge,
    disableBodyScroll,
    enableBodyScroll,
    PersonalMenu,
    personalMenuClassName,
    Popup,
    popupContentSlotClassNames,
    pxToRem,
    StyleRule,
    TopNavigationProps as AriaTopNavigationProps,
    useStyles,
    Drawer,
    pxArrayToRem,
    AppDisplayModeType,
    Avatar,
    AvatarProps,
} from '@archipro-design/aria';
import { CloseLine, CustomUser as UserIcon } from '@archipro-design/icons';
import { Actions } from 'ahooks/lib/useBoolean';
import { useEffect, useRef } from 'react';
import { useCreatePersonalMenuProps } from '../hooks/useCreatePersonalMenuProps';
import {
    Notifications,
    RenderAnchorTag,
    TopNavFeatureFlags,
    TopNavRef,
    User,
} from '../types';
import { TopNavFeatures } from '../TopNavigation';
import { MOBILE_TOP_NAV_FULL_LOGO_HEIGHT } from '../styles';
import * as S from './svgIconSlotOverride.style';

import { Location } from 'history';
import { useUpdateEffect } from 'ahooks';

export function useAvatarSlot(
    user: User,
    features: TopNavFeatures,
    renderAnchorTag: RenderAnchorTag,
    appDisplayMode: AppDisplayModeType,
    topNavRef: TopNavRef,
    menuActionsState: [boolean, Actions],
    personalMenuState: [boolean, Actions],
    showFullLogoActions: Actions,
    notifications?: Notifications | null,
    defaultFullLogo?: boolean,
    location?: Location,
    featureFlags?: TopNavFeatureFlags
): AriaTopNavigationProps['avatarSlot'] {
    const avatarRef = useRef<HTMLDivElement>(null);
    const personalMenuRef = useRef<HTMLDivElement>(null);

    const target = (
        appDisplayMode.desktop ? avatarRef.current : topNavRef.current
    ) as HTMLDivElement;

    const personalMenuProps = useCreatePersonalMenuProps({
        renderAnchorTag,
        user,
        features,
        notifications,
        onClosePopup: () => {
            target && target.click();
        },
        appDisplayMode,
        featureFlags,
    });

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles({
        mobile: appDisplayMode.mobile,
        desktop: appDisplayMode.desktop,
    });

    let defaultAvatarProps: AvatarProps = {};
    if (user.__typename === 'Me') {
        defaultAvatarProps = {
            initials: user.Initials ?? undefined,
            ...(user.ProfileImage && {
                image: {
                    src: user.ProfileImage,
                    alt: 'Avatar',
                },
            }),
            variables: { showBorder: false, borderWidth: '0' },
        };
    }

    const isPersonalMenuOpen = menuActionsState[0] || personalMenuState[0];
    useEffect(() => {
        if (!personalMenuRef?.current || !appDisplayMode.mobile) return;
        const target = personalMenuRef.current;
        const mainList = target.childNodes[0];
        if (!mainList) return;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (isPersonalMenuOpen)
            disableBodyScroll(mainList as unknown as HTMLElement);
        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            if (isPersonalMenuOpen)
                enableBodyScroll(mainList as unknown as HTMLElement);
        };
    }, [isPersonalMenuOpen, appDisplayMode]);

    // Hide the backrdrop when the personnal menu is closed (triggered by user scroll in TopNagivation)
    useEffect(() => {
        if (!personalMenuState[0]) {
            menuActionsState[1].setFalse();
        }
    }, [personalMenuState[0]]);

    useUpdateEffect(() => {
        if (personalMenuState[0]) {
            personalMenuState[1].setFalse();
            menuActionsState[1].setFalse();
        }
    }, [location]);

    if (user.__typename === 'Guest' || !personalMenuProps) {
        return {
            key: 'sign-in',
            children: appDisplayMode.desktop ? (
                'SIGN IN'
            ) : (
                <UserIcon
                    aria-label={'user'}
                    size={'2x'}
                    className={css(S.strokeSvgIconOverride)}
                />
            ),
            transparent: true,
            size: appDisplayMode.desktop ? 14 : 40,
            ...renderAnchorTag('/login'),
        };
    }

    //tech-debt - split into a seperate file
    if (!appDisplayMode.desktop) {
        return {
            key: 'avatarSlot',
            icon: (
                <Badge
                    count={notifications?.userInbox?.count || 0}
                    anchorVertical="top"
                >
                    <Avatar size={28} ref={avatarRef} {...defaultAvatarProps} />
                </Badge>
            ),
            onClick: () => {
                personalMenuState[1].toggle();
            },
            children: (Component, props) => {
                return (
                    <>
                        <Component {...props} />
                        <Drawer
                            fluid={true}
                            anchor={'bottom'}
                            variables={{
                                contentBorderRadius: pxArrayToRem([
                                    12, 12, 0, 0,
                                ]),
                                contentBackgroundColor: '#ffffff',
                            }}
                            open={personalMenuState[0]}
                            onOpenChange={(_, data) =>
                                personalMenuState[1].set(!!data?.open)
                            }
                            swipeableEdge={{
                                onEdgeSwipe: (_, dir) => {
                                    if (dir === 'down') {
                                        personalMenuState[1].setFalse();
                                    }
                                },
                            }}
                            content={
                                <PersonalMenu
                                    {...personalMenuProps}
                                    className={css(PersonalMenuMobileContent)}
                                    ref={personalMenuRef}
                                    variant="02"
                                    variables={{
                                        personalMenuBackgroundColor: '#ffffff',
                                    }}
                                />
                            }
                        />
                    </>
                );
            },
        };
    }

    return {
        key: 'avatarSlot',
        styles: {
            ':hover': { textDecoration: 'none' },
        },
        icon: (
            <>
                {appDisplayMode.mobile && isPersonalMenuOpen ? (
                    <CloseLine size={'2x'} aria-label={'menu1'} />
                ) : appDisplayMode.mobile ? (
                    <Badge
                        count={notifications?.userInbox?.count || 0}
                        anchorVertical="top"
                    >
                        <Avatar
                            size={28}
                            ref={avatarRef}
                            {...defaultAvatarProps}
                        />
                    </Badge>
                ) : (
                    <Avatar
                        // TS2590: Expression produces a union type that is too complex to represent.
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ref={avatarRef}
                        size={28}
                        {...defaultAvatarProps}
                    />
                )}
            </>
        ),
        children: (Component, props) => {
            return (
                <Popup
                    open={personalMenuState[0]}
                    key={'avatarSlotPopup'}
                    position="below"
                    align={'end'}
                    trigger={<Component {...props} />}
                    target={target}
                    onOpenChange={() => {
                        menuActionsState[1].toggle();
                        personalMenuState[1].toggle();
                        if (appDisplayMode.mobile) {
                            if (defaultFullLogo) {
                                showFullLogoActions.setTrue();
                            } else {
                                showFullLogoActions.toggle();
                            }
                        }
                    }}
                    trapFocus={true}
                    content={
                        personalMenuProps
                            ? {
                                  className: css(
                                      TopNavigationPersonalMenuPopoverContent
                                  ),
                                  children: (
                                      <PersonalMenu
                                          {...personalMenuProps}
                                          ref={personalMenuRef}
                                          variables={{
                                              contentPadding: pxToRem(0),
                                          }}
                                      />
                                  ),
                              }
                            : null
                    }
                />
            );
        },
    };
}

const PersonalMenuMobileContent: StyleRule = () => ({
    paddingBottom: pxToRem(30),
});

const TopNavigationPersonalMenuPopoverContent: StyleRule<{
    desktop: boolean;
}> = ({ theme, desktop }) => {
    const desktopStyle = {
        position: 'relative',
        top: pxToRem(28),
        right: pxToRem(0),
    };
    return {
        width: '100%',
        height: `calc(100% - ${MOBILE_TOP_NAV_FULL_LOGO_HEIGHT})`,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        [`& .${popupContentSlotClassNames.content}`]: {
            position: 'relative',
            width: '100%',
            height: '100%',
            boxShadow: 'none',
            [theme.screen.tablet]: {
                boxShadow: '0px 14px 24px 2px rgba(0, 0, 0, 0.08)',
                width: pxToRem(245),
                right: pxToRem(16),
            },
            ...(desktop && {
                [theme.screen.laptop]: desktopStyle,
                [theme.screen.max.shrankWindow]: desktopStyle,
            }),
            [`& .${personalMenuClassName}`]: {
                width: '100%',
                height: '100%',
            },
        },

        [theme.screen.tablet]: {
            width: 'unset',
            height: 'unset',
        },
    };
};
