import {
    pxToRem,
    pxArrayToRem,
    labelClassName,
    buttonClassName,
} from '@archipro-design/aria';
import { svgIconClassName } from '@archipro-design/icons';
import type { StyleRule } from '@archipro-design/aria';
export const MonthlyUserCounterContainer: StyleRule = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(12),
    width: '100%',
    [theme.screen.tablet]: {
        display: 'block',
        margin: pxToRem(7),
    },
});
export const Counter: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    color: theme.siteVariables.colors.primitive.white,
    opacity: 0.8,
    lineHeight: '94%',
    margin: pxArrayToRem([0, 10]),
    [theme.screen.max.tablet]: {
        fontSize: pxToRem(72),
    },
    [theme.screen.tablet]: {
        margin: 0,
        fontSize: pxToRem(88),
    },
});
export const CaptionHolder: StyleRule = ({ theme }) => ({
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    gap: pxToRem(42),
    [theme.screen.tablet]: {
        flexDirection: 'row',
        alignItems: 'center',
        width: pxToRem(556),
        gap: 0,
    },
});
export const CounterCaption: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Medium65,
    color: theme.siteVariables.colors.primitive.white,
    opacity: 0.8,
    lineHeight: '94%',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    margin: pxArrayToRem([3, 8]),
    [theme.screen.max.tablet]: {
        fontSize: pxToRem(16),
    },
    [theme.screen.tablet]: {
        fontSize: pxToRem(14),
        margin: pxArrayToRem([4, 0]),
    },
});
export const Signup: StyleRule = ({ theme }) => ({
    [`& .${labelClassName}`]: {
        ...theme.siteVariables.textStyles.Heading.Medium65,
        color: theme.siteVariables.colors.primitive.white,
        letterSpacing: pxToRem(-0.16),
        textTransform: 'uppercase',
    },
    width: '100%',
    height: pxToRem(48),
    backgroundColor: theme.siteVariables.colors.gold['200'],
    [`& .${svgIconClassName}`]: {
        margin: pxArrayToRem([0, 0, 3, 5]),
    },
    [theme.screen.max.tablet]: {
        [`& .${labelClassName}`]: {
            fontSize: pxToRem(16),
        },
    },
    [theme.screen.tablet]: {
        width: pxToRem(145),
        height: pxToRem(63),
        [`& .${labelClassName}`]: {
            fontSize: pxToRem(15),
            letterSpacing: 0,
        },
        [`& .${svgIconClassName}`]: {
            display: 'none',
        },
        [`&.${buttonClassName}:hover`]: {
            backgroundColor: theme.siteVariables.colors.gold['300'],
        },
    },
});
