import { useEffect, useState } from 'react';
import { Tracker } from '../../types';

import type { Location } from 'history';
import {
    TRACK_DOWNLOAD_CLASSNAME,
    TRACK_REFERRAL_CLASSNAME,
} from '../../constants';
import { useTrackDownloadClick } from './useTrackDownloadClick';
import { useTrackWebsiteReferralClick } from './useTrackWebsiteReferralClick';

export const useLogLinkClicks = (
    location: Location,
    trackerInstance: Tracker
) => {
    const [trackedLinks, setTrackedLinks] = useState(new Set<string>());

    const trackDownload = useTrackDownloadClick(trackerInstance);
    const trackWebsiteReferral = useTrackWebsiteReferralClick(trackerInstance);

    // We limit tracked links to one event per URL per page to prevent duplicate events.
    // Browsing between pages will reset this and allow the same event to be tracked again.
    useEffect(() => {
        setTrackedLinks(trackedLinks => {
            // No need to update the state if the sets already empty. Avoid re-rendering.
            if (trackedLinks.size === 0) {
                return trackedLinks;
            }
            return new Set<string>();
        });
    }, [location]);

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            let target = event.target as HTMLElement;
            while (target && target !== document.body) {
                if (target.tagName === 'A') {
                    if (
                        event.button === 0 || // Left mouse button / mobile touch start
                        event.button === 1 || // Middle mouse button
                        event.button === 2 // Right mouse button
                    ) {
                        let href = (target as HTMLAnchorElement).href;
                        const url = new URL(href);
                        url.searchParams.delete('_ap_pageid');
                        url.searchParams.delete('utm_source');
                        url.searchParams.delete('utm_medium');
                        url.searchParams.delete('utm_campaign');
                        url.searchParams.delete('__apid');
                        href = url.toString();

                        if (trackedLinks.has(href)) {
                            console.log('Link already tracked');
                            return;
                        }

                        let eventTracked = false;

                        if (
                            target.classList.contains(TRACK_DOWNLOAD_CLASSNAME)
                        ) {
                            trackDownload(target, event.button);
                            eventTracked = true;
                        }

                        if (
                            target.classList.contains(TRACK_REFERRAL_CLASSNAME)
                        ) {
                            trackWebsiteReferral(target, event.button);
                            eventTracked = true;
                        }

                        if (eventTracked) {
                            setTrackedLinks(prev => new Set(prev).add(href)); // Add to tracked links
                        }
                    }
                }
                target = target.parentElement as HTMLElement;
            }
        };

        document.body.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.body.removeEventListener('mousedown', handleMouseDown);
        };
    }, [trackedLinks, trackerInstance, trackDownload, trackWebsiteReferral]);
};
