import React, {
    ReactNode,
    SyntheticEvent,
    useEffect,
    useRef,
    useState,
} from 'react';
import { usePrevious, useBoolean, useEventListener } from 'ahooks';
import {
    Box,
    AppDisplayModeType,
    Flex,
    Provider,
    pxArrayToRem,
    pxToRem,
    TopNavigation as AriaTopNavigation,
    useStyles,
    useTheme,
    revertTheme,
    baseTheme,
    useDeviceTypeContext,
} from '@archipro-design/aria';
import {
    Cart,
    DirectoryMenuItems,
    MegaMenuProps,
    SubNavMenuProps,
    Notifications,
    RenderAnchorTag,
    SearchProps,
    TopNavRef,
    User,
    TopnavClientEnvironment,
    Directory,
    TopNavFeatureFlags,
} from './types';
import { getThemeByHoverNav } from './utils/getThemeByHoverNav';
import { renderAnchorTag as defaultRenderAnchorTag } from './utils/renderAnchorTag';
import { DEFAULT_DIRECTORY_MENU_ITEMS } from './utils/defaultDirectoryItems';
import { CartSlotPopupState, useCartSlot } from './slots/cartSlot';
import { favouriteSlot } from './slots/favouriteSlot';
import { useHandleNotifications } from './hooks/useHandleNotifications';
import { useSearchProps } from './hooks/useSearchProps';
import { useHandleTopNavTransitions } from './hooks/useHandleTopNavTransitions';
import * as S from './styles';
import { Location } from 'history';
import { useMegaMenu, DEFAULT_SHOP_LABEL } from './hooks/useMegaMenu';
import { useLogoAnimationTransition } from './hooks/useLogoAnimationTransition';
import { useAvatarSlot } from './slots/avatarSlot';
import { useInboxSlot } from './slots/inboxSlot';
import { useFlagSlot } from './slots/flagSlot';
import { useAppMenuSlot } from './slots/appMenuSlot';
import { useCategoryTree, UseCategoryTreeOpts } from './hooks/useCategoryTree';
import {
    getReseneLogoAdEventData,
    useAdEventHandler,
    useTracker,
} from '@archipro-website/tracker';
import { getThemeLegacyByUrl } from './utils/getThemeLegacyByUrl';
import { useBurgerMenuButtonSlot } from './slots/burgerMenuButtonSlot';
import { hex2rgba } from './utils/hex2rgba';
import { MegaMenuReseneSponsor } from './components/megamenu-resene-sponsor/MegaMenuReseneSponsor';
import { Actions } from 'ahooks/lib/useBoolean';
import { useLocalStorageState } from 'ahooks';
import { getCategoryFromUrl } from './utils/getCategoryFromUrl';
import { AppMenuReseneSponsor } from './components/appmenu-resene-sponsor/AppMenuReseneSponsor';

export interface TopNavFeatures {
    canCreateProfessional: boolean;
}
export interface TopNavigationProps {
    /**
     * MUST BE MEMOIZED
     */
    renderAnchorTag: RenderAnchorTag;
    appDisplayMode: AppDisplayModeType;
    /**
     * The result of the useLocation hook.
     * Both apps have different versions of react-router
     * so we need to pass it in to avoid dependency resolution issues.
     */
    location: Location;
    env: TopnavClientEnvironment;
    assetsBaseUrl: string;

    logo?: { defaultShowFullLogo?: boolean };

    user: User;
    /** Nullable due to the potential of lazy loading */
    notifications?: Notifications | null;
    cart: {
        data: Cart | null;
        cartPopupState: CartSlotPopupState;
    };

    /**
     * when top nav needs to know about certain permissions
     * it requires them to be passed in
     */
    features: TopNavFeatures;
    directoryMenuItems?: DirectoryMenuItems;
    /**
     * Everything related to searching, and user interaction
     * with the search feature
     */
    search: SearchProps;
    megaMenu: MegaMenuProps;

    /**
     * Notification polling.
     * Use to fetch notifications externally
     */
    onPoll: () => void;

    // Additional elements rendered below the nav
    children?: ReactNode;

    disableSlideAnimation?: boolean;

    // Force a background color on float
    backgroundColorOnFloat?: string;

    categoryTree: Pick<
        UseCategoryTreeOpts,
        'data' | 'onFetchCategoryTree' | 'onNavigate'
    >;

    /**
     * Display only the top right nav items
     * see design boards for an example
     */
    minimalTopNav?: boolean;
    alwaysFloating?: boolean;
    appType?: 'web' | 'manager';
    featureFlags?: TopNavFeatureFlags;
    subNavMenu: SubNavMenuProps;
    onMouseLeave?: () => void;
    onMenuDrawerOpen?: (val: boolean) => void;
    interactionType?: 'hover' | 'click';
    transparent?: boolean;
    personalMenuState: [boolean, Actions];
    flagMenuState: [boolean, Actions];
    showReseneSponsor: boolean;
    deviceType: ReturnType<typeof useDeviceTypeContext>;
}

const searchPreFilterOptions: Record<string, Directory | null> = {
    All: null,
    Products: 'product',
    Professionals: 'professional',
    Projects: 'project',
    Articles: 'article',
};

const searchPreFilterLabelArray = Object.keys(searchPreFilterOptions);

export function TopNavigation(props: TopNavigationProps): JSX.Element {
    let defaultDirectoryItems = DEFAULT_DIRECTORY_MENU_ITEMS;
    if (!props.featureFlags?.shopNavEnabled) {
        defaultDirectoryItems = {
            ...DEFAULT_DIRECTORY_MENU_ITEMS,
            products: {
                ...DEFAULT_DIRECTORY_MENU_ITEMS.products,
                labelPrefix: 'Search',
            },
        };
    }

    const {
        directoryMenuItems: directoryMenuItemsProp = defaultDirectoryItems,
        renderAnchorTag = defaultRenderAnchorTag,
        appDisplayMode,
        logo,
        user,
        notifications,
        cart,
        onPoll,
        children,
        search,
        megaMenu,
        location,
        env,
        assetsBaseUrl,
        features,
        disableSlideAnimation = false,
        backgroundColorOnFloat,
        categoryTree: categoryTreeProp,
        minimalTopNav = false,
        alwaysFloating = true,
        appType,
        featureFlags,
        subNavMenu,
        onMouseLeave: onMouseLeaveProp,
        onMenuDrawerOpen,
        interactionType = 'hover',
        transparent = false,
        personalMenuState,
        flagMenuState,
        showReseneSponsor,
        deviceType,
    } = props;

    const topNavRef: TopNavRef = useRef<HTMLDivElement>(null);
    const topNavState = useHandleTopNavTransitions(topNavRef);
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get('search');

    const menuActionsState = useBoolean(false);
    const [showMenu, expandedMenuActions] = menuActionsState;
    const [showPersonalMenu, expandedPersonalMenuActions] = personalMenuState;
    const [showFlagMenu, expandedFlagMenuActions] = flagMenuState;
    const hasHover = interactionType === 'hover';

    const { DIRECTORY: currentDirectory } = getCategoryFromUrl(location);

    const [searchCategory, setSearchCategory] =
        useLocalStorageState<Directory | null>('prefilter-search-category', {
            defaultValue: null,
            deserializer: value => {
                if (!featureFlags?.searchPreFiliteringEnabled) {
                    return null;
                }
                return JSON.parse(value) as Directory | null;
            },
        });

    useEffect(() => {
        if (searchCategory !== currentDirectory) {
            setSearchCategory(null);
        }
    }, [currentDirectory]);

    const [activeCategoryTree, setActiveCategoryTree] = useState<
        string | undefined
    >(undefined);

    const prevUserType = usePrevious(user.__typename);
    const showSearchBarState = useBoolean(
        (search.defaultSearchValue !== undefined &&
            search.defaultSearchValue !== '') ||
            search.isActive
    );

    const theme = useTheme();

    // This will force the menu state false when the logout happens. Otherwise, these values stick.
    useEffect(() => {
        if (
            user.__typename !== prevUserType &&
            user.__typename !== 'Me' &&
            (showMenu || showPersonalMenu) &&
            !showFlagMenu
        ) {
            expandedPersonalMenuActions.set(false);
            expandedFlagMenuActions.setFalse();
        }
    }, [
        appDisplayMode,
        expandedMenuActions,
        expandedPersonalMenuActions,
        expandedFlagMenuActions,
        prevUserType,
        showMenu,
        showFlagMenu,
        showPersonalMenu,
        user.__typename,
    ]);

    const [showSearchBar, showSearchBarAction] = showSearchBarState;

    const { current: directoryMenuItems } = useRef(
        // Does not need to change after the initial mount
        Object.values(directoryMenuItemsProp).map(item => ({
            ...item,
            ...renderAnchorTag(item.href),
        }))
    );

    // Side Effect: When location changes close megamenu. For mobile search menu shop button directing to /products?forSale=1
    useEffect(() => {
        expandedMenuActions.set(false);
    }, [location]);

    useHandleNotifications(user, onPoll);

    const tracker = useTracker();
    const defaultFullLogo = !!logo?.defaultShowFullLogo;

    const {
        megaMenu: megaMenuProps,
        directory,
        setDirectory,
        onMouseOver,
        onMouseLeave,
        handleChangeMinorNavigationItem,
        megaMenuState,
        setMegaMenuState,
        subNavigationItems,
        onSubNavItemMouseOver,
        onSubNavItemMouseLeave,
        handleChangeSubNavigationItem,
    } = useMegaMenu(
        location,
        user,
        renderAnchorTag,
        megaMenu,
        defaultFullLogo,
        subNavMenu
    );

    const resetAppMenu = () => {
        setActiveCategoryTree(undefined);
        expandedMenuActions.setFalse();
        showSearchBarAction.setFalse();
    };

    const { categoryTree } = useCategoryTree({
        active: appDisplayMode.mobile && showMenu,
        onNavigate: (to, navigationType) => {
            categoryTreeProp?.onNavigate(to, navigationType);
            resetAppMenu();
        },
        data: categoryTreeProp?.data,
        onFetchCategoryTree: categoryTreeProp?.onFetchCategoryTree,
        activeCategoryLink: activeCategoryTree,
        onActiveCategoryLinkChange: setActiveCategoryTree,
        renderAnchorTag: renderAnchorTag,
        appType,
    });

    const { showFullLogo, setLogoAnimation, showFullLogoActions } =
        useLogoAnimationTransition({
            disableSlideAnimation,
            defaultFullLogo: defaultFullLogo || showSearchBar,
            topNavState,
            appDisplayMode,
            location,
            megaMenuState,
            showPersonalMenu,
        });

    const { showBackdropSearch, setShowBackdropSearch, ...searchProps } =
        useSearchProps({
            location,
            currentSearch: search.defaultSearchValue,
            searchData: search.data,
            showSearchBarState,
            onSearchClick: (e, data) => {
                search.onClick(e, data);
                resetAppMenu();
            },
            onSearchType: search.onChange,
            onSearchNavigate: search.onNavigate,
            setLogoAnimation,
            setMegaMenuState,
            showFullLogoActions,
            showSearchBarAction,
            expandedMenuActions,
            defaultFullLogo,
            setDirectory,
            renderAnchorTag,
            appDisplayMode,
            showPersonalMenu,
            topNavRef,
            appType,
            searchCategory,
        });
    const avatarProps = useAvatarSlot(
        user,
        features,
        renderAnchorTag,
        appDisplayMode,
        topNavRef,
        menuActionsState,
        personalMenuState,
        showFullLogoActions,
        notifications,
        defaultFullLogo,
        location,
        featureFlags
    );

    const flagProps = useFlagSlot(
        appDisplayMode,
        topNavRef,
        menuActionsState,
        flagMenuState,
        env,
        location
    );

    const showTransparentTopNav =
        !cart.cartPopupState.open &&
        !showSearchBar &&
        !showMenu &&
        transparent &&
        topNavState === 'static';

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { keyframe } = useStyles();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles({
        topNavState,
        slideInKeyframes: keyframe(S.slideInKeyframes),
        slideUpKeyframes: keyframe(S.slideUpKeyframes),
        showFullLogo,
        backgroundColorOnFloat,
        categoryTreeActive:
            search.isActive &&
            appDisplayMode.mobile &&
            !searchQuery &&
            !showPersonalMenu,
        showSearchBar: !!searchProps.showSearchBar,
        showSubNavigation:
            !searchProps.showSearchBar && subNavigationItems.length > 0,
        showBanner: Boolean(
            megaMenuProps.active ||
                searchProps.showSearchMenu ||
                showPersonalMenu
        ),
        isDesktop: appDisplayMode.desktop,
        isMegamenuActive: megaMenuProps.active,
        minimalTopNav,
        alwaysFloating,
        transparent: showTransparentTopNav,
    });

    const cartSlot = useCartSlot(
        appDisplayMode,
        notifications?.cart.count || 0,
        cart.cartPopupState,
        cart.data,
        Boolean(notifications?.cart.count) ||
            Boolean(featureFlags?.shopNavEnabled)
    );

    const inboxSlot = useInboxSlot(
        notifications?.userInbox?.count,
        renderAnchorTag
    );

    const menuButtonSlot = useBurgerMenuButtonSlot({
        open: showMenu,
        onOpenChange: (_, data) => {
            expandedMenuActions.set(Boolean(data));
        },
    });

    const reseneAd = getReseneLogoAdEventData();
    const reseneAdRef = useRef(null);
    const { onClick: onReseneLogoClick } = useAdEventHandler(
        reseneAd,
        reseneAdRef,
        {
            disableServeEvent: true,
        }
    );

    const appMenu = useAppMenuSlot({
        renderAnchorTag,
        onCategoryItemClick: link => {
            setActiveCategoryTree(link);
        },
        showSearchMenu: searchProps.showSearchMenu,
        shopNavEnabled: featureFlags?.shopNavEnabled,
        assetsBaseUrl,
        bottomNavigationAdditionalContent: showReseneSponsor
            ? {
                  children: (
                      <AppMenuReseneSponsor
                          ref={reseneAdRef}
                          assetsBaseUrl={assetsBaseUrl}
                          renderAnchorTag={renderAnchorTag}
                          onClick={() => {
                              onReseneLogoClick('Ad_CTAClick');
                          }}
                      />
                  ),
              }
            : undefined,
    });

    useEventListener('scroll', () => {
        expandedFlagMenuActions.setFalse();
        if (disableSlideAnimation) {
            setLogoAnimation(undefined);
            setMegaMenuState(undefined);
        }
    });

    useEffect(() => {
        if (appDisplayMode.desktop || !onMenuDrawerOpen) return;
        const shouldOpen = showMenu || searchProps.showSearchMenu;
        onMenuDrawerOpen(shouldOpen ?? false);
    }, [searchProps.showSearchMenu, showMenu]);

    const reseneSponsorProps = showReseneSponsor
        ? {
              leftNavigationAdditionalContent: {
                  children: (
                      <MegaMenuReseneSponsor
                          ref={reseneAdRef}
                          assetsBaseUrl={assetsBaseUrl}
                          renderAnchorTag={renderAnchorTag}
                          onClick={() => {
                              onReseneLogoClick('Ad_CTAClick');
                          }}
                      />
                  ),
              },
          }
        : {};
    const pageDirectory = location.pathname.startsWith('/ecommerce-launch')
        ? 'ecommerce-launch'
        : directory ?? undefined;

    const transparentTheme = showTransparentTopNav ? revertTheme : undefined;

    const mobileMenuExpandedBase =
        appDisplayMode.mobile && showMenu ? baseTheme : undefined;

    const topNavTheme =
        mobileMenuExpandedBase ||
        transparentTheme ||
        getThemeByHoverNav(pageDirectory) ||
        getThemeLegacyByUrl(location.pathname);

    const disableHideOnSearch =
        disableSlideAnimation || searchProps.showSearchBar;

    return (
        <Provider theme={topNavTheme}>
            <Flex column className={css(S.TopNavigationContainer)}>
                <AriaTopNavigation
                    ref={topNavRef}
                    appDisplayMode={appDisplayMode}
                    className={
                        disableHideOnSearch
                            ? css(S.disableTopNavigationAnimation)
                            : css(S.enableTopNavigationAnimation)
                    }
                    logo={{
                        ...renderAnchorTag('/'),
                        showFull: true,
                        animationType: undefined,
                        ...(minimalTopNav && { design: { display: 'none' } }),
                        onClick: () => {
                            void tracker.log('TopNavLogoClick', {
                                url: new URL(window.location.href),
                                targetTracker: 'archiproTracker',
                            });
                            showSearchBarAction.setFalse();
                            setShowBackdropSearch.setFalse();
                        },
                    }}
                    hasMenuTopDivider={false}
                    secondaryNavItems={
                        searchProps.showSearchBar
                            ? []
                            : subNavigationItems.map((item, index) => {
                                  const isShop =
                                      item.name === DEFAULT_SHOP_LABEL;
                                  return {
                                      children: item.name,
                                      ...renderAnchorTag(item.link),
                                      onFocus: (
                                          e: SyntheticEvent<HTMLElement, Event>
                                      ) =>
                                          handleChangeSubNavigationItem(e, {
                                              category: item.link,
                                              directory: subNavMenu.directory,
                                          }),
                                      onMouseOver: (
                                          e: SyntheticEvent<HTMLElement, Event>
                                      ) => {
                                          if (!hasHover) return;
                                          onSubNavItemMouseOver?.(e, {
                                              category: item.link,
                                              directory: subNavMenu.directory,
                                          });
                                          if (showPersonalMenu) {
                                              expandedPersonalMenuActions.setFalse();
                                              expandedMenuActions.setFalse();
                                          }
                                          if (showFlagMenu) {
                                              expandedFlagMenuActions.setFalse();
                                              expandedMenuActions.setFalse();
                                          }
                                      },
                                      onClick: () => {
                                          tracker.log('SecondaryNavClick', {
                                              url: new URL(
                                                  window.location.href
                                              ),
                                              data: {
                                                  ExtraData: JSON.stringify({
                                                      index,
                                                      option: item.name,
                                                      link: item.link,
                                                  }),
                                              },
                                              targetTracker: 'archiproTracker',
                                          });
                                      },
                                      onMouseLeave: onSubNavItemMouseLeave,
                                      variables: {
                                          buttonRadius: pxToRem(50),

                                          primaryButtonBackgroundColorNormal:
                                              isShop
                                                  ? theme.siteVariables.colors
                                                        .danger['300']
                                                  : theme.siteVariables.colors
                                                        .charcoal['000'],
                                          primaryButtonBackgroundColorHover:
                                              isShop
                                                  ? hex2rgba(
                                                        theme.siteVariables
                                                            .colors.danger[
                                                            '300'
                                                        ],
                                                        0.7
                                                    )
                                                  : theme.siteVariables.colors
                                                        .gold['000'],
                                      },
                                  };
                              })
                    }
                    navigationItems={directoryMenuItems.slice().map(item => {
                        return {
                            ...item,
                            ...(minimalTopNav && {
                                design: { display: 'none' },
                            }),
                            labelPrefix: item.labelPrefix ?? '',
                            active: directory === item.key,
                            onFocus: (e: SyntheticEvent<HTMLElement, Event>) =>
                                handleChangeMinorNavigationItem(e, item.key),
                            onMouseOver: (
                                e: SyntheticEvent<HTMLElement, Event>
                            ) => {
                                if (!hasHover) return;
                                onMouseOver(e, item.key);
                                if (showPersonalMenu) {
                                    expandedPersonalMenuActions.setFalse();
                                    expandedMenuActions.setFalse();
                                }
                                if (showFlagMenu) {
                                    expandedFlagMenuActions.setFalse();
                                    expandedMenuActions.setFalse();
                                }
                            },
                            onMouseLeave: onMouseLeave,
                            onClick: (
                                event: React.MouseEvent<HTMLAnchorElement>
                            ) => {
                                event.currentTarget.blur();
                                void tracker.log('MMOpenTopNav', {
                                    url: new URL(window.location.href),
                                    targetTracker: 'archiproTracker',
                                    data: {
                                        ExtraData: item.href,
                                    },
                                });
                            },
                        };
                    })}
                    onMouseLeave={(
                        event: React.SyntheticEvent<HTMLElement, Event>
                    ) => {
                        handleChangeMinorNavigationItem(event);
                        handleChangeSubNavigationItem(event);
                        onMouseLeaveProp?.();
                    }}
                    expanded={showMenu}
                    onMenuExpand={(_e, expanded = false) => {
                        expandedMenuActions.set(expanded);
                    }}
                    actionTextSlot={undefined}
                    cartSlot={cartSlot ?? undefined}
                    favouriteSlot={favouriteSlot(
                        0, // hide badge PLAT-5513
                        renderAnchorTag
                    )}
                    inboxSlot={
                        appDisplayMode.mobile && user.__typename !== 'Guest'
                            ? inboxSlot
                            : undefined
                    }
                    avatarSlot={avatarProps}
                    flagSlot={appDisplayMode.desktop ? flagProps : undefined}
                    menuButtonSlot={menuButtonSlot}
                    showCategoryTree={!!activeCategoryTree}
                    appMenu={!appDisplayMode.desktop ? appMenu : undefined}
                    megaMenu={
                        appDisplayMode.desktop &&
                        megaMenuProps.active &&
                        megaMenuProps.menu &&
                        hasHover && {
                            ...megaMenuProps.menu,
                            ...reseneSponsorProps,
                        }
                    }
                    preFilterDropdown={
                        featureFlags?.searchPreFiliteringEnabled &&
                        showSearchBarState[0]
                            ? {
                                  items: searchPreFilterLabelArray,
                                  defaultValue: searchCategory
                                      ? searchPreFilterLabelArray.find(
                                            key =>
                                                searchPreFilterOptions[key] ===
                                                searchCategory
                                        )
                                      : searchPreFilterLabelArray[0],
                                  variant: '01',
                                  onChange: (e, data) => {
                                      if (typeof data.value !== 'string')
                                          return;

                                      tracker.log('prefilterChanged', {
                                          url: new URL(window.location.href),
                                          targetTracker: [
                                              'ga4Tracker',
                                              'archiproTracker',
                                          ],
                                          data: {
                                              ExtraData: `previous: ${
                                                  searchCategory ?? 'All'
                                              }, new: ${data.value}`,
                                          },
                                          ga4Data: {
                                              ExtraData: `previous: ${
                                                  searchCategory ?? 'All'
                                              }, new: ${data.value}`,
                                          },
                                      });
                                      const value =
                                          searchPreFilterOptions[data.value];
                                      setSearchCategory(value);
                                  },
                              }
                            : undefined
                    }
                    categoryTree={categoryTree ?? undefined}
                    {...(!minimalTopNav && { ...searchProps })}
                    variables={{
                        ...(minimalTopNav && {
                            topNavigationDesktopPadding: pxArrayToRem([
                                50, 19, 0, 50,
                            ]),
                        }),
                        secondaryNavContainerPadding: '0',
                        ...(showTransparentTopNav && {
                            topNavigationBackgroundColor: 'transparent',
                        }),
                    }}
                    deviceType={deviceType}
                />
                {(showBackdropSearch ||
                    showMenu ||
                    showPersonalMenu ||
                    (megaMenuProps.active && hasHover)) &&
                    appDisplayMode.desktop && (
                        <Box
                            key="TopNavigationBackdrop"
                            styles={{
                                width: '100%',
                                height: '100vh',
                                position: 'fixed',
                                left: 0,
                                top: 0,
                                zIndex: theme.siteVariables.zIndexes.topNav - 1,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            }}
                        />
                    )}
                {children}
            </Flex>
        </Provider>
    );
}
