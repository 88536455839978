import type { StyleRule } from '@archipro-design/aria';

export const PageBase: StyleRule = () => ({
    minHeight: '100vh',

    '@supports(height: 100dvh)': {
        minHeight: '100dvh',
    },
});

export const PageContent: StyleRule = () => ({
    flex: '1',
});
