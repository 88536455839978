import type { Logger } from '@archipro-website/logger';
import { shuffleArray } from '~/utils/arrayHelper';
import { adRequestClient } from '../api/fetch-ad';
import { getFeaturedTileViewModelFromAdvertCreative } from '~/modules/navigation/api/get-featured-tile-from-ad-creative';
import type { DirectoryItemTile } from '~/modules/directory/type';

// Sponsorship placement is available for the projects and professionals only.
const isSponsorshipAdCategory = (pathname: string) => {
    return (
        pathname.startsWith('/projects') ||
        pathname.startsWith('/professionals')
    );
};

// Query sponsorship ads for mega menu.
// Return the ad data if any.
// Return null if no ad for the category.
// Return undefined if ad is not applicable for the category or error.
export const fetchMegaMenuAdForCategory = async (
    logger: Logger
): Promise<DirectoryItemTile | null | undefined> => {
    try {
        const requestUrl = window.location.href;
        const url = new URL(requestUrl);
        if (!isSponsorshipAdCategory(url.pathname)) {
            return undefined;
        }
        const adsResp = await adRequestClient({
            requestUrl,
            placementType: 'SponsorshipMegaMenuPlacement',
            addPathContext: ['region'],
            logger,
        });
        let ad = null;
        if (adsResp && adsResp.length > 0) {
            ad = shuffleArray(adsResp)[0] ?? null;
        }
        if (ad) {
            return getFeaturedTileViewModelFromAdvertCreative(ad);
        }
        return null;
    } catch (e) {
        logger.error('fail to fetch Mega Menu Ad for category', e);
        return undefined;
    }
};
