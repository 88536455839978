import { useCallback } from 'react';
import { Tracker } from '../../types';
import {
    REFERRAL_ATTR_ITEM_ID,
    REFERRAL_ATTR_PROFESSIONAL_TITLE,
} from '../../constants';

export const useTrackWebsiteReferralClick = (trackerInstance: Tracker) => {
    return useCallback(
        (target: HTMLElement, mouseButton: 0 | 1 | 2) => {
            const professionalTitle = target.getAttribute(
                REFERRAL_ATTR_PROFESSIONAL_TITLE
            );

            const itemId = target.getAttribute(REFERRAL_ATTR_ITEM_ID);

            if (!professionalTitle) {
                console.warn(
                    `Missing attribute: ${REFERRAL_ATTR_PROFESSIONAL_TITLE}. Tracking performance will be degraded.`
                );
                return;
            }
            if (!itemId) {
                console.error(
                    `Missing attribute: ${REFERRAL_ATTR_ITEM_ID}. Critical tracking failure.`
                );
                return;
            }

            if (!(target instanceof HTMLAnchorElement)) {
                console.error('Target element is not an anchor element');
                return;
            }

            let externalURL = target.href;
            const url = new URL(externalURL);
            url.searchParams.delete('_ap_pageid');
            url.searchParams.delete('utm_source');
            url.searchParams.delete('utm_medium');
            url.searchParams.delete('utm_campaign');
            url.searchParams.delete('__apid');
            externalURL = url.toString();

            trackerInstance
                .log('Website', {
                    url: new URL(window.location.href),
                    label: professionalTitle,
                    data: {
                        // The backend will figure out the ProfessionalID from this
                        LibraryItemID: itemId,
                        ExternalURL: externalURL,
                        ExtraData: {
                            MouseButton: mouseButton,
                        },
                    },
                })
                .then(() => {
                    //
                })
                .catch(() => {
                    //
                });
        },
        [trackerInstance]
    );
};
