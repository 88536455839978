import type {
    AcceptedTiles,
    MegaMenuProps,
    ShorthandValue,
} from '@archipro-design/aria';
import type { RenderAnchorTag } from '@archipro-website/top-navigation';
import type { ArticleTileViewModel } from '@modules/article/type';
import { getArticleTile } from '@modules/article/util/get-article-tile';
import type { DirectoryItemTile } from '@modules/directory/type';
import type { ProductTileViewModel } from '@modules/product/type';
import { getProductTile } from '@modules/product/util/get-product-tile';
import type { ProfessionalTileViewModel } from '@modules/professionals-category/type';
import { getProfessionalTile } from '@modules/professionals-category/util/get-professional-tile';
import type { ProjectTileViewModel } from '@modules/projects-category/type';
import { getProjectTile } from '@modules/projects-category/util/get-project-tile';
import type { RefObject } from 'react';

export const getFeaturedTile = (
    item: DirectoryItemTile,
    renderAnchorTag: RenderAnchorTag,
    onClick: () => void,
    ref?: RefObject<HTMLElement>
): MegaMenuProps['featuredTile'] => {
    const title = 'Featured';
    let instanceProps: ShorthandValue<AcceptedTiles>;
    switch (item.type) {
        case 'product':
            instanceProps = getProductTile(
                item as ProductTileViewModel,
                renderAnchorTag
            );
            return {
                ref,
                title,
                tile: { type: 'product', instanceProps },
                onClick: () => {
                    onClick();
                },
            };
        case 'project':
            instanceProps = getProjectTile(
                item as ProjectTileViewModel,
                renderAnchorTag
            );
            return {
                ref,
                title,
                tile: { type: 'project', instanceProps },
                onClick: () => {
                    onClick();
                },
            };
        case 'professional':
            instanceProps = getProfessionalTile(
                item as ProfessionalTileViewModel,
                renderAnchorTag
            );
            return {
                ref,
                title,
                tile: { type: 'professional', instanceProps },
                onClick: () => {
                    onClick();
                },
            };
        case 'article':
            instanceProps = getArticleTile(
                item as ArticleTileViewModel,
                renderAnchorTag
            );
            return {
                ref,
                title,
                tile: { type: 'article', instanceProps },
                onClick: () => {
                    onClick();
                },
            };
        default:
            throw new Error('Unsupported feature tile type');
    }
};
