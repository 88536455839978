import { useEffect, useState } from 'react';

interface NetworkState {
    isOnline: boolean;
    loaded: boolean;
}

// ahooks useNetwork not working during SSR
// and this hook doesn't work on window...
export default function useNetworkState() {
    // navigator is not available during SSR
    const [state, setState] = useState<NetworkState>({
        isOnline: true,
        loaded: false,
    });

    useEffect(() => {
        const onLoad = () => {
            setState({ isOnline: navigator.onLine, loaded: true });
        };

        const onOnline = () => {
            setState(preState => ({ ...preState, isOnline: true }));
        };

        const onOffline = () => {
            setState(preState => ({ ...preState, isOnline: false }));
        };

        window.addEventListener('load', onLoad);
        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);

        return () => {
            window.removeEventListener('load', onLoad);
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        };
    }, []);

    return { isOnline: state.isOnline };
}
