import type { ProjectTileProps, ShorthandValue } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import type { RenderAnchorTag } from '@archipro-website/top-navigation';
import type { ProjectTileViewModel } from '../type';
/* 
This function has been duplicated at /projects/archipro-website/themes/archipro2/javascript/react/layout/TopNavigation/components/TopNavigationAria/getFeaturedTile.tsx
and must be kept in sync
*/
export const getProjectTile = (
    item: ProjectTileViewModel,
    renderAnchorTag: RenderAnchorTag
): Partial<ShorthandValue<ProjectTileProps>> => {
    return {
        image: [item.image],
        projectName: item.projectName,
        professionalName: item.professionalName,
        variables: {
            width: pxToRem(462),
        },
        ...renderAnchorTag(item.projectLink),
    };
};
