import type { StyleRule } from '@archipro-design/aria';
import {
    DESKTOP_TOP_NAV_HEIGHT_WITH_SECOND_NAV,
    pxToRem,
} from '@archipro-design/aria';
import {
    MOBILE_TOP_NAV_LARGE_LOGO_HEIGHT,
    TABLET_TOP_NAV_FULL_LOGO_HEIGHT,
    DESKTOP_TOP_NAV_FULL_LOGO_HEIGHT,
} from '@archipro-website/top-navigation';

export const TopNavigationWrapper: StyleRule<{
    isDesktop: boolean;
    backToTopEnabled: boolean;
    showTransparentTopNav: boolean;
}> = ({ theme, isDesktop, backToTopEnabled, showTransparentTopNav }) => ({
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: theme.siteVariables.zIndexes.topNav,
    ...(isDesktop &&
        !backToTopEnabled && {
            [theme.screen.max.shrankWindow]: {
                position: showTransparentTopNav ? 'fixed' : 'sticky',
            },
        }),
    ...(backToTopEnabled && {
        [theme.screen.tablet]: {
            position: 'relative',
        },
    }),
});

export const TopNavigationSpacer: StyleRule<{
    isDesktop: boolean;
    showSubNavigation: boolean;
    backToTopEnabled: boolean;
}> = ({ theme, isDesktop, showSubNavigation, backToTopEnabled }) => {
    const desktopStyle = {
        height: showSubNavigation
            ? pxToRem(DESKTOP_TOP_NAV_HEIGHT_WITH_SECOND_NAV)
            : DESKTOP_TOP_NAV_FULL_LOGO_HEIGHT,
    };
    return {
        backgroundColor: theme.siteVariables.bodyBackground,
        height: MOBILE_TOP_NAV_LARGE_LOGO_HEIGHT,
        [theme.screen.tablet]: {
            height: TABLET_TOP_NAV_FULL_LOGO_HEIGHT,
        },
        ...(isDesktop && {
            [theme.screen.laptop]: backToTopEnabled
                ? {
                      height: 0,
                  }
                : desktopStyle,
            [theme.screen.max.shrankWindow]: {
                height: 0,
            },
        }),
    };
};
