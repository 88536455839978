import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    footerNavigationSlotClassNames,
    pxArrayToRem,
    captionClassName,
} from '@archipro-design/aria';
export const FooterContainer: StyleRule<{ showCounter?: boolean }> = ({
    theme,
    showCounter,
}) => ({
    [`& .${footerNavigationSlotClassNames.right}`]: {
        flexDirection: showCounter ? 'column-reverse' : 'column',
        [theme.screen.tablet]: {
            flexDirection: 'column',
        },
    },
    [`& .${footerNavigationSlotClassNames.subscription}`]: {
        [theme.screen.max.tablet]: {
            marginTop: pxToRem(5),
            marginBottom: pxToRem(80),
        },
    },
});

export const AOCSectionContainer: StyleRule<{
    forceBackground?: string;
}> = ({ forceBackground }) => ({
    ...(forceBackground && {
        backgroundColor: forceBackground,
    }),
});

export const AOCSection: StyleRule<{
    onDarkPage: boolean;
}> = ({ theme, onDarkPage }) => ({
    padding: pxArrayToRem([60, 50]),
    maxWidth: pxToRem(1920),

    [`& .${captionClassName}`]: {
        display: 'block',
        maxWidth: pxToRem(700),
        lineHeight: theme.siteVariables.lineHeightSmaller,
        letterSpacing: theme.siteVariables.letterSpacingSmall,
        color: onDarkPage
            ? theme.siteVariables.colors.white['100A']
            : '#0D0E12',
    },
    [theme.screen.clamped]: {
        paddingLeft: `max(0px, calc(${pxToRem(1920 + 50)} - 100vw))`,
        margin: 'auto',
    },
});
