import type { UIMatch } from '@remix-run/react';
import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { useAppDisplayModeContext } from '@archipro-design/aria';
import type { AppDisplayMode } from '@archipro-design/aria';
import type { APHandle, HandleHideNavigation } from '@modules/root/type/handle';

export interface UseHideNavigationResult {
    topNavigation?: boolean;
    footerNavigation?: boolean;
}

interface HideNavigationHandleArgs {
    hideTopNavOn?: HandleHideNavigation;
    hideFooterOn?: HandleHideNavigation;
    displayMode?: AppDisplayMode;
}

export const hideNavigationHandle = (
    args: HideNavigationHandleArgs
): UseHideNavigationResult => {
    const { hideTopNavOn, hideFooterOn, displayMode } = args;

    return {
        topNavigation:
            !displayMode || !hideTopNavOn ? true : !hideTopNavOn[displayMode],
        footerNavigation:
            !displayMode || !hideFooterOn ? true : !hideFooterOn[displayMode],
    };
};

export const useHideNavigation = (
    matches: UIMatch[]
): UseHideNavigationResult => {
    const routeError = useRouteError();

    const hideFooterOn = (
        matches.find(
            (match) => match.handle && match.handle.hasOwnProperty('hideFooter')
        ) as UIMatch<unknown, APHandle> | undefined
    )?.handle?.hideFooter;

    const hideTopNavOn = (
        matches.find(
            (match) =>
                match.handle && match.handle.hasOwnProperty('hideTopNavigation')
        ) as UIMatch<unknown, APHandle> | undefined
    )?.handle?.hideTopNavigation;

    const appDisplayMode = useAppDisplayModeContext();

    if (isRouteErrorResponse(routeError) && routeError.status === 401) {
        return {
            topNavigation: false,
            footerNavigation: false,
        };
    }

    if (!hideFooterOn && !hideTopNavOn) {
        return {
            topNavigation: true,
            footerNavigation: true,
        };
    }

    return hideNavigationHandle({
        hideTopNavOn,
        hideFooterOn,
        displayMode: (Object.keys(appDisplayMode) as AppDisplayMode[]).find(
            (key) => appDisplayMode[key] === true
        ) as AppDisplayMode,
    });
};
