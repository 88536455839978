import { StyleRule } from '@archipro-design/aria';

export const svgIconOverride: StyleRule = () => ({
    '& svg': {
        fill: 'currentColor',
        '& path': {
            fill: 'currentColor',
        },
    },
});

export const strokeSvgIconOverride: StyleRule = () => ({
    '& svg': {
        stroke: 'currentColor',
        '& path': {
            stroke: 'currentColor',
        },
        '& rect': {
            stroke: 'currentColor',
        },
    },
});
