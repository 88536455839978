import * as S from './Page403.style';
import { isUserLoggedIn } from '~/modules/user';
import {
    useTheme,
    Avatar,
    useStyles,
    Caption,
    Subheader,
    Button,
} from '@archipro-design/aria';
import { getLoginRedirectUrl } from '~/modules/root/util';
import type { CurrentMemberFragment } from 'generated/graphql';
import BackgroundOverlay from '~/modules/error/component/page-403/BackgroundOverlay';
import { useLogout } from '~/modules/user/hook/use-logout';
import type { User } from '~/server/types';

const Page403 = ({ user }: { user?: User }) => {
    const theme = useTheme();
    const { css } = useStyles();
    const isLoggedIn = user ? isUserLoggedIn(user) : false;
    const { onLogoutClick } = useLogout(getLoginRedirectUrl());

    const { Email, ProfileImage, Initials } = user as CurrentMemberFragment;

    return (
        <BackgroundOverlay>
            <div className={css(S.AccessNotice)}>
                <Caption variant="03-alt" className={css(S.Title)}>
                    You need access
                </Caption>
                <Subheader variant="02" className={css(S.MainText)}>
                    Ask for access, or switch to an account with access.
                </Subheader>
                {isLoggedIn && (
                    <div className={css(S.AvatarAndEmail)}>
                        <Avatar
                            image={ProfileImage}
                            initials={Initials || undefined}
                            size={64}
                            variables={{
                                initialsColor:
                                    theme.siteVariables.colors.white['FFF'],
                                avatarBackgroundColor:
                                    theme.siteVariables.colors.charcoal[250],
                            }}
                        />

                        <Caption
                            variant="03-alt"
                            className={css(S.EmailAddress)}
                        >
                            {Email}
                        </Caption>
                    </div>
                )}
                {user ? (
                    <Button
                        className={css(S.SwitchButton)}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onLogoutClick();
                        }}
                        color="dark"
                    >
                        SWITCH ACCOUNT
                    </Button>
                ) : null}
            </div>
        </BackgroundOverlay>
    );
};

export default Page403;
