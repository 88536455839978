import type { StyleRule } from '@archipro-design/aria';
import { popupContentSlotClassNames, pxToRem } from '@archipro-design/aria';

export const ShoppingCartPopupContent: StyleRule = () => ({
    width: pxToRem(382),
    maxWidth: pxToRem(382),
});

export const ShoppingCartPopup: StyleRule = () => ({
    [`& .${popupContentSlotClassNames.content}`]: {
        transform: `translate(${pxToRem(-50)}, ${pxToRem(-76)})`,
    },
});
