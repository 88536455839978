import { useEffect } from 'react';
import { useLocalStorageState } from 'ahooks';
import { useAuthGating } from './use-auth-gating';
import { useLocation } from '@remix-run/react';
import { parseURL } from '@archipro-website/top-navigation';
import { useAuth } from '~/modules/root/context';

const MAX_VIEW_PAGES = 2;
const LOGIN_MODAL_SUBTITLE = 'Please log in or sign up to continue browsing';

interface PageView {
    previousPage?: string;
    viewItems: number;
}

export const useSiteGate = () => {
    const { enabled: siteGateEnabled } = useAuthGating('site-gating');
    const { showAuthModal } = useAuth();
    const { pathname } = useLocation();
    const { basePath } = parseURL(pathname);

    const [pageViewInfo, setPageViewInfo] = useLocalStorageState<PageView>(
        'page-view',
        { defaultValue: { viewItems: 0 } }
    );

    useEffect(() => {
        if (siteGateEnabled && pageViewInfo.previousPage !== basePath) {
            setPageViewInfo({
                previousPage: basePath,
                viewItems: pageViewInfo.viewItems + 1,
            });
        }
    }, [siteGateEnabled, basePath, pageViewInfo, setPageViewInfo]);

    useEffect(() => {
        if (!siteGateEnabled) return;

        if (pageViewInfo.viewItems > MAX_VIEW_PAGES) {
            showAuthModal({
                authSource: 'siteGate',
                disableCloseButton: true,
                subtitle: LOGIN_MODAL_SUBTITLE,
            });
        }
    }, [pageViewInfo.viewItems, siteGateEnabled, showAuthModal]);
};
