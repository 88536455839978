import type { Directory } from '@archipro-website/top-navigation';

export const getDirectoryTypeByPathname = (pathname: string): Directory => {
    const basePath = pathname.split('/')[1] ?? 'default';

    switch (basePath) {
        case 'professional':
        case 'professionals':
            return 'professional';
        case 'project':
        case 'projects':
            return 'project';

        case 'article':
        case 'articles':
            return 'article';
        case 'product':
        case 'products':
        default:
            return 'product';
    }
};
