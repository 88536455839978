import {
    AppDisplayModeType,
    BadgeProps,
    PersonalMenuProps,
    PersonalProfileMenuProps,
    ShorthandValue,
} from '@archipro-design/aria';
import type { CountryContext } from '@archipro-website/localisation';
import {
    AnalyticsStatusEnum,
    PermissionEnum,
} from '../../../generated/graphql';
import {
    LoggedInUser,
    Notifications,
    RenderAnchorTag,
    TopNavFeatureFlags,
} from '../types';
import { getBMPagePath } from './get-bm-page-path';

type BusinessProfileUser = Pick<LoggedInUser, 'TeamMembers' | '__typename'>;

const BUSINESS_MENU_ITEMS = [
    {
        label: 'Inbox',
        link: (urlSegment: string) => {
            return `/business/${urlSegment}/inbox`;
        },
        isEnabled: ({ permissions }: { permissions: PermissionEnum[] }) => {
            return permissions.includes(PermissionEnum.BmInboxView);
        },
    },
    {
        label: 'Analytics',
        link: (urlSegment: string) => {
            return `/business/${urlSegment}/analytics/overview?month=12`;
        },
        isEnabled: ({
            permissions,
            analyticsStatus,
            appDisplayMode,
            featureFlags,
        }: {
            permissions: PermissionEnum[];
            analyticsStatus: AnalyticsStatusEnum;
            appDisplayMode: AppDisplayModeType;
            featureFlags?: TopNavFeatureFlags;
        }) => {
            if (appDisplayMode.mobile) {
                return false;
            }
            if (featureFlags?.ba2NavEnabled && !appDisplayMode.desktop) {
                return false;
            }
            return (
                permissions.includes(PermissionEnum.BmAnalyticsView) &&
                analyticsStatus !== AnalyticsStatusEnum.Disabled
            );
        },
    },
] as const;

function getBadgeProps(
    item: (typeof BUSINESS_MENU_ITEMS)[number],
    professional: BusinessProfileUser['TeamMembers'][0]['Professional'],
    notifications: Notifications
): ShorthandValue<BadgeProps> {
    switch (item.label) {
        case 'Inbox': {
            const inbox = notifications?.companyInboxes?.find(c => {
                return professional && c && c.id && c.id === professional.ID;
            });
            return inbox?.count ?? 0;
        }
        default:
            return null;
    }
}

export const createBusinessProfileProps = (opts: {
    countryCtx: CountryContext;
    renderAnchorTag: RenderAnchorTag;
    user: BusinessProfileUser;
    notifications?: Notifications | null;
    appDisplayMode: AppDisplayModeType;
    featureFlags?: TopNavFeatureFlags;
}): PersonalMenuProps['businessProfiles'] | undefined => {
    const {
        user,
        countryCtx,
        renderAnchorTag,
        notifications,
        appDisplayMode,
        featureFlags,
    } = opts;

    const businessProfiles = user.TeamMembers.map(teamMember => {
        const professional = teamMember.Professional;
        const menuItems: PersonalProfileMenuProps['menuItems'] = [];
        BUSINESS_MENU_ITEMS.forEach(item => {
            const { label, link, isEnabled } = item;
            if (
                isEnabled?.({
                    analyticsStatus: professional.AnalyticsStatus,
                    permissions: professional.Permissions,
                    appDisplayMode,
                    featureFlags,
                })
            ) {
                menuItems.push({
                    label,
                    ...renderAnchorTag(
                        typeof link === 'function'
                            ? link(professional.URLSegment)
                            : link
                    ),
                    badge: notifications
                        ? getBadgeProps(item, professional, notifications)
                        : undefined,
                    key: label,
                });
            }
        });

        return {
            professionalName: professional.Title ?? undefined,
            countryTag: professional.Country ?? '',
            professionalHref: getBMPagePath(professional.URLSegment),
            unreadMessages:
                notifications?.companyInboxes?.find(c => {
                    return (
                        professional && c && c.id && c.id === professional.ID
                    );
                })?.count ?? 0,
            menuItems,
        };
    });

    return countryCtx.byCountry({
        NZ: businessProfiles,
        AU: businessProfiles,
    });
};
