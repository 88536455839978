import type { AdvertCreative } from '@modules/adverts/type';
import type { ProductTileViewModel } from '@modules/product/type';
import type { ProjectTileViewModel } from '@modules/projects-category/type';
import type { ProfessionalTileViewModel } from '@modules/professionals-category/type';
import type { ArticleTileViewModel } from '@modules/article';
import type { DirectoryItemTile } from '~/modules/directory/type';
import { getEstimateReadTimeLabel } from '@modules/articles/util';
import { utcDateToLocalTimezone } from '@modules/date-time/util';

export const getFeaturedTileViewModelFromAdvertCreative = (
    ad: AdvertCreative
): DirectoryItemTile | null => {
    switch (ad.section) {
        case 'Product':
            let variants: ProductTileViewModel['variants'] = [];
            let price = '';
            let pricePrefix: ProductTileViewModel['pricePrefix'] = '';

            if (ad.parent.productDetail) {
                if (ad.parent.productDetail?.pricingDisplay?.ReplacedText) {
                    pricePrefix =
                        ad.parent.productDetail.pricingDisplay.ReplacedText;
                } else if (ad.parent.productDetail?.pricingDisplay?.Prefix) {
                    pricePrefix = ad.parent.productDetail.pricingDisplay.Prefix;
                }

                if (ad.parent.productDetail?.pricingDisplay?.Text) {
                    price = ad.parent.productDetail.pricingDisplay.Text;
                }

                if (ad.parent.productDetail.colors) {
                    variants = ad.parent.productDetail.colors?.map((c) => {
                        return {
                            color: c.code || undefined,
                            image: c.desktop || undefined,
                        };
                    });
                }
            }

            const product: ProductTileViewModel = {
                ID: ad.parent.id || 0,
                type: 'product',
                image: ad.carousel[0]?.image.desktop2x ?? '',
                professionalID: ad.professional.id,
                professionalName: ad.professional.title,
                productName: ad.title,
                link: ad.cta.href,
                ad: ad,
                price: price,
                variants: variants,
                pricePrefix: pricePrefix,
            };

            return product;
        case 'Project':
            const project: ProjectTileViewModel = {
                ID: ad.parent.id || 0,
                type: 'project',
                image: ad.carousel[0]?.image.desktop2x ?? '',
                projectName: ad.title,
                professionalID: ad.professional.id,
                professionalName: ad.professional.title,
                professionalLink: ad.professional.href,
                projectLink: ad.cta.href,
                ad: ad,
            };

            return project;
        case 'Professional':
            const memberSince = ad.parent?.professionalDetail?.memberSince;

            const professional: ProfessionalTileViewModel = {
                ID: ad.parent.id || 0,
                type: 'professional',
                image: ad.carousel[0]?.image.desktop2x ?? '',
                logo: ad.professional.logo,
                logoBackgroundColor:
                    ad.professional.logoBackgroundColor ?? 'white',
                title: ad.title,
                memberSince: memberSince
                    ? new Date(memberSince).getFullYear().toString()
                    : undefined,
                link: ad.cta.href,
                professionalID: ad.professional.id,
                services: ad.parent?.professionalDetail.services || [],
                category:
                    ad.parent?.professionalDetail.category || ad.textContent,
                ad: ad,
            };

            return professional;
        case 'Article':
            const article: ArticleTileViewModel = {
                ID: ad.parent.id || 0,
                type: 'article',
                image: ad.carousel[0]?.image.desktop2x ?? '',
                readTime: ad.parent.articleDetail.wordCount
                    ? getEstimateReadTimeLabel(
                          ad.parent.articleDetail.wordCount
                      )
                    : '',
                title: ad.title,
                link: ad.cta.href,
                ad: ad,
                professionalID: ad.professional.id,
                author: ad.parent?.articleDetail?.authorName
                    ? ad.parent.articleDetail.authorName
                    : '',
                byArchiPro: false, // TODO: Add to ads model
                date: ad.parent?.articleDetail?.publishedDate
                    ? utcDateToLocalTimezone(
                          ad.parent.articleDetail.publishedDate
                      ).format('DD MMMM YYYY')
                    : '',
            };

            return article;
        default:
            throw new Error('Unsupported feature tile type');
    }
};
