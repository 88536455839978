import type { DataFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import {
    getCombinedNotifications,
    getMegaMenuNavigation,
    getTopNavigation,
} from '@modules/navigation';
import { getPageLocalisation } from '~/modules/seo/api/get-page-localisation.server';
import { getShoppingCartSummary } from '@modules/ecommerce/api/get-shopping-cart-summary.server';
import { hasFeature } from '@modules/root/util';
import { getPermissions } from '~/modules/user';
import { PermissionEnum } from 'generated/graphql';
import type { GrantedPermissions } from '~/modules/user/type';
import { getSession } from '~/modules/root/util/session.server';
import type { ImageConfigProps } from '~/modules/images';
import getMonthlyUserCount from '@modules/navigation/api/get-monthly-user-count';
import { getProfessionalBookingLink } from '@modules/professional/api/get-professional-booking-link.server';
import AppPage from '@modules/root/page/AppPage';
import { toggleFeatures } from '~/modules/root/util/toggle-features';

/**
 * global catch and error boundary for all pages under __public
 * Child routes can still catch their own but if they don't we'll end up here
 * root.tsx will still render top nav etc
 */
export { ErrorBoundary, links } from '@modules/root/page/AppPage';

export const loader = async (args: DataFunctionArgs) => {
    const { context, request } = args;

    const response = toggleFeatures(request);
    if (response) throw response;

    const [
        user,
        topNavigation,
        localisation,
        notifications,
        shoppingCartSummary,
        monthlyUserCount,
        megamenu,
        abTestConfig,
        session,
        professionalBookingLink,
    ] = await Promise.all([
        context.getUser(),
        getTopNavigation(context),
        getPageLocalisation(args),
        getCombinedNotifications(context),
        getShoppingCartSummary(context),
        getMonthlyUserCount(args),
        getMegaMenuNavigation(context, request),
        context.getABTests(),
        getSession(request),
        getProfessionalBookingLink(context, request),
    ]);
    const rootGrants: GrantedPermissions = {
        editProfile: getPermissions(user, PermissionEnum.BmProfileEdit),
        editAdvertAdmin: getPermissions(user, PermissionEnum.BmAdvertAdminEdit),
    };
    const remixEnabled = hasFeature(user.FeatureFlags, 'remix');
    const ecommerceEnabled = hasFeature(user.FeatureFlags, 'ecommerce');
    const backToTopEnabled = hasFeature(
        user.FeatureFlags,
        'back_to_top_button'
    );
    const tabletEnabled = hasFeature(user.FeatureFlags, 'enable_tablet');
    const shopNavEnabled = hasFeature(user.FeatureFlags, 'shopnav');
    const footerCounterEnabled = hasFeature(user.FeatureFlags, 'footer_update');

    const bookConsultationEnabled = hasFeature(
        user.FeatureFlags,
        'bookconsultation'
    );

    const ecommerceActiveEnabled = hasFeature(
        user.FeatureFlags,
        'ecommerce_active'
    );
    const searchPreFiliteringEnabled = hasFeature(
        user.FeatureFlags,
        'search_pre_filtering'
    );
    const ba2NavEnabled = hasFeature(user.FeatureFlags, 'ba_two_new_nav');

    const imageConfigProps: ImageConfigProps = {
        notEscapeURLParams:
            user.FeatureFlags.find(
                (item) => item.Name === 'ap_image_not_escape_url_params'
            )?.Enabled ?? false,
    };

    const autoSubmitSuccess = session.getAutoSubmitSuccess();

    const availableForWorkEnabled = hasFeature(
        user.FeatureFlags,
        'available_for_work'
    );

    return json(
        {
            user,
            topNavigation,
            localisation,
            notifications,
            shoppingCartSummary,
            rootGrants,
            remixEnabled,
            ecommerceEnabled,
            backToTopEnabled,
            shopNavEnabled,
            ecommerceActiveEnabled,
            searchPreFiliteringEnabled,
            ba2NavEnabled,
            bookConsultationEnabled,
            footerCounterEnabled,
            canCreateProfessional: getPermissions(
                user,
                PermissionEnum.BmProfessionalCreate
            ),
            megamenu,
            toastMessage: session.getToastMessage(),
            autoSubmitSuccess,
            tabletEnabled,
            abTestConfig,
            imageConfigProps,
            showReseneSponsor: false,
            availableForWorkEnabled,
            monthlyUserCount,
            professionalBookingLink: bookConsultationEnabled
                ? professionalBookingLink
                : null,
        },
        {
            headers: await session.getHeaders(),
        }
    );
};

const App = () => <AppPage />;

export default App;
