import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const CartHeader: StyleRule = ({ theme }) => ({
    padding: `0 ${theme.siteVariables.spaces['space-8']}`,
    columnGap: theme.siteVariables.spaces['space-24'],
    minHeight: pxToRem(61),
    alignItems: 'center',
    borderTop: '2px solid transparent',
    borderBottom: `2px solid ${theme.siteVariables.colors.charcoal[250]}`,
});
