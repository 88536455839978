import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    pxArrayToRem,
    inputSlotClassNames,
    boxClassName,
    SubheaderClassName,
    labelClassName,
    captionClassName,
} from '@archipro-design/aria';

export const NewProfessionalHeaderInner: StyleRule = ({ theme }) => ({
    borderBottom: `2px solid ${theme.siteVariables.colors.charcoal['250']}`,
    padding: pxArrayToRem([8, 8, 5]),
    width: '100%',
    marginBottom: pxToRem(32),
    [`& .${SubheaderClassName}`]: {
        letterSpacing: theme.siteVariables.letterSpacingNormal,
        fontWeight: 500,
        color: theme.siteVariables.colors.charcoal['250'],
    },
});

export const NewProfessionalFooterInner: StyleRule = () => ({
    width: '100%',
});

export const NewProfessionalWrapper: StyleRule = ({ theme }) => ({
    padding: 0,
    marginBottom: pxToRem(32),

    [`& .${labelClassName}`]: {
        paddingLeft: pxToRem(8),
        letterSpacing: theme.siteVariables.letterSpacingMedium,
        color: theme.siteVariables.colors.charcoal['250'],
    },
});

export const FormRow: StyleRule = ({ theme }) => ({
    width: '100%',
    borderBottom: `2px solid ${theme.siteVariables.colors.charcoal['250']}`,
});

export const FormRowInner: StyleRule = ({ theme }) => ({
    padding: pxArrayToRem([8, 8, 0, 8]),
    gridGap: pxToRem(2),
    [theme.screen.laptop]: {
        padding: 0,
        minHeight: pxToRem(74),
        alignItems: `center`,
        gridGap: 0,
    },
});

export const FormRowMessage: StyleRule = () => ({
    minHeight: pxToRem(24),
    alignItems: `center`,
    padding: pxArrayToRem([0, 8, 0, 0]),

    [`& .${captionClassName}`]: {
        marginBottom: pxToRem(8),
    },
});

export const FormInput: StyleRule = ({ theme }) => ({
    [`& > .${boxClassName}`]: {
        display: 'flex',
    },
    [`& .${inputSlotClassNames.input}`]: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(16),
        width: `100%`,
        border: `none`,
        height: pxToRem(42),

        [`&::placeholder`]: {
            color: theme.siteVariables.colors.grey['150'],
            opacity: 1,
        },
    },

    [theme.screen.laptop]: {
        [`& .${inputSlotClassNames.input}`]: {
            fontSize: pxToRem(24),
            lineHeight: theme.siteVariables.lineHeightSmaller,
            letterSpacing: theme.siteVariables.letterSpacingMedium,
            paddingLeft: pxToRem(8),
            height: 'unset',
        },
    },
});

export const ErrorMessage: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.contextualColors.danger['100'],
});
