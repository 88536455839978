import type { ArchizeenTileProps, ShorthandValue } from '@archipro-design/aria';
import type { ArticleTileViewModel } from '../type';
import { pxToRem } from '@archipro-design/aria';
import type { RenderAnchorTag } from '@archipro-website/top-navigation';
import { getWordsByLabel } from '~/modules/articles/util';
/* 
This function has been duplicated at /projects/archipro-website/themes/archipro2/javascript/react/layout/TopNavigation/components/TopNavigationAria/getFeaturedTile.tsx
and must be kept in sync
*/
export const getArticleTile = (
    article: ArticleTileViewModel,
    renderAnchorTag: RenderAnchorTag
): Partial<ShorthandValue<ArchizeenTileProps>> => {
    return {
        image: {
            src: article.image,
            alt: article.title,
        },
        readTime: article.readTime,
        date: article.date,
        title: article.title,
        size: 'medium',
        variant: '03',
        preview: '',
        variables: {
            width: pxToRem(308),
        },
        label: getWordsByLabel({
            authorName: article.author,
            byArchiPro: article.byArchiPro,
            professionalName: article.professionalTitle,
        }),
        ...renderAnchorTag(article.link),
    };
};
