import { PersonalMenuProps, AppDisplayModeType } from '@archipro-design/aria';
import { LoggedInUser, RenderAnchorTag } from '../types';

type ProfileUser = Pick<
    LoggedInUser,
    'FirstName' | 'LastName' | 'Initials' | 'ProfileImage' | 'MemberImage'
>;

export const createPersonalProfileProps = (
    user: ProfileUser,
    renderAnchorTag: RenderAnchorTag,
    appDisplayMode: AppDisplayModeType
): PersonalMenuProps['personalProfile'] => {
    return {
        fullName: `${user.FirstName} ${user.LastName}`,
        ...renderAnchorTag('/member/edit-profile'),
        ...(!appDisplayMode.desktop && {
            avatar: {
                initials: (user.Initials as string) ?? undefined,
                image: user.MemberImage?.LargeAvatar || user.ProfileImage,
            },
        }),
    };
};
