import type {
    ErrorResponse,
    MessageResponse,
} from '@modules/root/graphql/responses';
import {
    isRouteErrorResponse,
    useRouteError,
    useSearchParams,
} from '@remix-run/react';
import { LoginPage } from '~/modules/user';

const Page401 = () => {
    const error = useRouteError();
    let welcomeMessage = '';
    let skipPostSignUpRedirect = false;
    const [searchParams] = useSearchParams();

    if (isRouteErrorResponse(error)) {
        const errorResponse: MessageResponse = error.data;
        const messageBody = errorResponse.message;
        try {
            const messageObj: MessageResponse = JSON.parse(messageBody);
            welcomeMessage = messageObj.message;
            skipPostSignUpRedirect = messageObj.skipPostSignup || false;
        } catch (e) {
            welcomeMessage = messageBody;
            skipPostSignUpRedirect = errorResponse?.skipPostSignup || false;
        }
    } else {
        welcomeMessage = (error as ErrorResponse).message;
        skipPostSignUpRedirect = false;
    }

    if (searchParams.get('skipPostSignUp') === '1') {
        skipPostSignUpRedirect = true;
    }

    return <LoginPage login={{ welcomeMessage, skipPostSignUpRedirect }} />;
};

export default Page401;
