import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import { hex2rgba } from '../../utils/hex2rgba';

export const CartItem: StyleRule = ({ theme }) => ({
    columnGap: theme.siteVariables.spaces['space-8'],
    padding: pxArrayToRem([12, 16]),
    maxWidth: pxToRem(382),
    '&:hover': {
        backgroundColor: hex2rgba(
            theme.siteVariables.colors.primitive.black,
            0.06
        ),
    },
});

export const ProductTitleContainer: StyleRule = () => ({
    minHeight: pxToRem(37),
});

export const ProductTitle: StyleRule = () => ({
    minHeight: pxToRem(33),
});

export const Pricing: StyleRule = () => ({
    marginTop: pxToRem(8.5),
    justifyContent: 'space-between',
});

export const Price: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.grey['300'],
    lineHeight: pxToRem(18),
});

export const SampleTag: StyleRule = ({ theme }) => ({
    backgroundColor: theme.siteVariables.colors.grey['300'],
    lineHeight: pxToRem(15),
    letterSpacing: '0.08em',
    padding: pxArrayToRem([2, 4]),
    color: theme.siteVariables.colors.white['000'],
});

export const Quantity: StyleRule = () => ({
    display: 'block',
    width: pxToRem(32),
    lineHeight: pxToRem(18),
    textAlign: 'center',
});
