import React, { useEffect, useState } from 'react';
import * as S from './CountrySuggestModal.styled';
import { Button, useStyles } from '@archipro-design/aria';
import { useCountryContext } from '@archipro-website/localisation';
import { getSiteUrl, getUserLocation } from '~/utils/countryHelper';
import { useLocalStorageState } from 'ahooks';
import { useTracker } from '@archipro-website/tracker';

const CountrySuggestModal = () => {
    const { code: siteCountryCode } = useCountryContext();
    const { css } = useStyles();
    const userCountryCode = getUserLocation();
    const [open, setOpen] = useState(false);
    const [country, setCountry] = useState('');
    const [pickedCountryCode, setPickedCountryCode] =
        useLocalStorageState<string>('ap_set_country_code', {
            defaultValue: '',
            serializer: (v) => v ?? '',
            deserializer: (v) => v,
        });
    const [link, setLink] = useState('https://archipro.co.nz');
    const tracker = useTracker();

    useEffect(() => {
        // User has confirmed they want this domain.
        if (pickedCountryCode === siteCountryCode) {
            return;
        }

        if (siteCountryCode === 'AU') {
            setLink(getSiteUrl('NZ'));
            setCountry('New Zealand');
            if (userCountryCode === 'NZ') {
                setOpen(true);
            }
        } else {
            setLink(getSiteUrl('AU'));
            setCountry('Australia');
            if (userCountryCode === 'AU') {
                setOpen(true);
            }
        }
    }, [pickedCountryCode, userCountryCode, siteCountryCode]);

    const onConfirm = () => {
        tracker.log('CountryModalClick', {
            url: new URL(window.location.href),
            targetTracker: 'archiproTracker',
            data: {
                ExtraData: 'Yes',
            },
        });
        window.location.href = link;
    };

    const onCancel = () => {
        tracker.log('CountryModalClick', {
            url: new URL(window.location.href),
            targetTracker: 'archiproTracker',
            data: {
                ExtraData: 'No',
            },
        });
        setPickedCountryCode(siteCountryCode || '');
        setOpen(false);
    };

    if (!open) {
        return null;
    }

    return (
        <div className={css(S.Base)}>
            <div className={css(S.Body)}>
                <div className={css(S.ModalTitle)}>
                    It seems like you&apos;re in{' '}
                    <span className={css(S.NoWrap)}>{country}</span>.
                </div>
                <div className={css(S.ModalText)}>
                    Would you like to redirect to{' '}
                    <span className={css(S.Link)} onClick={onConfirm}>
                        {new URL(link).hostname}
                    </span>
                    ?
                </div>
                <div className={css(S.Buttons)}>
                    <Button
                        size={18}
                        onClick={onCancel}
                        variant="outlined"
                        color="dark"
                        design={{
                            marginRight: '12rem',
                        }}
                    >
                        No thanks
                    </Button>
                    <Button onClick={onConfirm} size={18} color="dark">
                        Yes please
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CountrySuggestModal;
