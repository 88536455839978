import React from 'react';
import { useIdle } from 'react-use';
import { webpushObserver } from './webpush-observer';

export type Unregister = () => void;
export interface OnDeployEvent {
    version: string;
    host: string;
}
export type OnDeployment = (event: OnDeployEvent) => void;

export interface UseDeploymentShouldReloadProps {
    /**
     * this is the time user inactivity on the pages causes deregistration
     * from the pusher channel
     */
    idleTimeout: number;
    clientVersion: string;
    getServerVersion: () => Promise<string | undefined>;
    host: string;
    config: {
        appId: string;
        channelWeb: string;
        eventDeployed: string;
        enabled: boolean;
    };
}

export const useDeploymentShouldReload = ({
    idleTimeout,
    host,
    clientVersion,
    getServerVersion,
    config,
}: UseDeploymentShouldReloadProps) => {
    const unregisterRef = React.useRef<Unregister | undefined>(undefined);
    const serverVersion = React.useRef<string | undefined>();
    const idle = useIdle(idleTimeout, true);
    const { appId, eventDeployed, channelWeb, enabled } = config;
    const onDeployment: OnDeployment = React.useCallback(
        event => {
            if (event.host !== host) {
                /**
                 * This is a deployment event from another host
                 */
                return;
            }

            /**
             * set the server version from the event
             */
            console.log(
                `%c🎊 ${event.version} has been released.`,
                'color: white; background: green; padding: 10px; border-radius: 5px;'
            );
            serverVersion.current = event.version;
        },
        [serverVersion, host]
    );

    React.useEffect(() => {
        if (idle) {
            /**
             * when we are idling unregister
             */
            unregisterRef.current?.();
            unregisterRef.current = undefined;
        } else {
            /**
             * each time we go active get the current version in case a deployment
             * happened while we were idle
             * and register for pusher events
             */
            getServerVersion().then(
                version => (serverVersion.current = version)
            );
            if (window.top !== window.self) {
                /**
                 * means we are in an iframe. skip listening in inframes
                 */
                return;
            }
            if (unregisterRef.current) {
                return;
            }
            unregisterRef.current = webpushObserver({
                channelName: channelWeb,
                eventName: eventDeployed,
                appId,
                onDeployment,
            });
        }
    }, [
        idle,
        serverVersion,
        channelWeb,
        eventDeployed,
        appId,
        onDeployment,
        getServerVersion,
    ]);

    return React.useCallback(() => {
        if (
            enabled &&
            serverVersion.current !== undefined &&
            clientVersion !== serverVersion.current
        ) {
            console.log(
                `%cℹ️ New server version detected: "${serverVersion.current}". Reloading the app`,
                'color: white; background: green; padding: 10px; border-radius: 5px;'
            );
            return true;
        }
        return false;
    }, [serverVersion, clientVersion, enabled]);
};
