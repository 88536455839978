import type { ProductTileProps, ShorthandValue } from '@archipro-design/aria';
import type { RenderAnchorTag } from '@archipro-website/top-navigation';
import type { ProductTileViewModel } from '../type';

/* 
This function has been duplicated at /projects/archipro-website/themes/archipro2/javascript/react/layout/TopNavigation/components/TopNavigationAria/getFeaturedTile.tsx
and must be kept in sync
*/
export const getProductTile = (
    product: ProductTileViewModel,
    renderAnchorTag: RenderAnchorTag
): Partial<ShorthandValue<ProductTileProps>> => {
    const { pricePrefix, price } = product;
    return {
        image: [product.image],
        professionalName: {
            children: product.professionalName ?? '',
            uc: false,
        },
        productName: { children: product.productName, maximumLines: 2 },
        pricePrefix: pricePrefix || undefined,
        price,
        variants: product.variants?.map((variant, index) => ({
            key: index,
            children: variant.image ? (
                <img
                    src={variant.image}
                    alt="black-heirloom"
                    style={{ position: 'absolute' }}
                />
            ) : null,
            style: {
                backgroundColor: variant.color ?? 'transparent',
            },
        })),
        ...renderAnchorTag(product.link),
    };
};
