import { useUser } from '~/modules/user';
import { useLocalStorageState } from 'ahooks';
import { useNavigation, useSearchParams } from '@remix-run/react';
import { useEffect } from 'react';
import type { DesignBoardItem } from './use-design-board-gating';
import {
    PIN_ITEM_LOCAL_KEY,
    ADD_PIN_SEARCH_PARAM_KEY,
} from './use-design-board-gating';
import { useSaveToDesignBoardContext } from '../component/context';
import SaveToBoardChooseLocation from '../component/save-to-design-board/SaveToBoardChooseLocation';
import CreateNewBoardAndSavePin from '../component/save-to-design-board/CreateNewBoardAndSavePin';
import { AUTH_TYPE_PARAM_KEY } from '~/modules/root/hook/use-auth-event';

export const useDesignBoardAddPinFromLocal = () => {
    const isGuest = useUser().__typename === 'Guest';

    const [savingItem, setSavingItem] =
        useLocalStorageState<DesignBoardItem | null>(PIN_ITEM_LOCAL_KEY, {
            defaultValue: null,
        });

    const [searchParams, setSearchParams] = useSearchParams();
    const navigation = useNavigation();
    const isLoading = navigation.state !== 'idle';

    const saveToBoardContext = useSaveToDesignBoardContext();

    useEffect(() => {
        const addPin = !!searchParams.get(ADD_PIN_SEARCH_PARAM_KEY);

        // auth event search parameters will affect the design board logic
        // to make sure design board draw is open after social login
        // here only run the logic when authType is empty
        const authType = searchParams.get(AUTH_TYPE_PARAM_KEY);

        if (isLoading || !addPin || !savingItem || isGuest || authType) return;

        const closeDesignBoardDrawer = () => {
            saveToBoardContext.dispatch({
                type: 'CloseDrawer',
            });
        };

        const openCreateDesignBoardDrawer = () => {
            saveToBoardContext.dispatch({
                type: 'OpenDrawer',
                payload: {
                    drawerChild: (
                        <CreateNewBoardAndSavePin
                            selectedPinId={savingItem.itemId}
                            selectedPinType={savingItem.itemType}
                            urlSearchParams={savingItem.urlSearchParams}
                            libraryItemIdOverride={
                                savingItem.libraryItemIdOverride
                            }
                            onClose={closeDesignBoardDrawer}
                        />
                    ),
                },
            });
        };

        const openSaveDesignBoardDrawer = () => {
            saveToBoardContext.dispatch({
                type: 'OpenDrawer',
                payload: {
                    drawerChild: (
                        <SaveToBoardChooseLocation
                            selectedPinId={savingItem.itemId}
                            selectedPinType={savingItem.itemType}
                            urlSearchParams={savingItem.urlSearchParams}
                            libraryItemIdOverride={
                                savingItem.libraryItemIdOverride
                            }
                            onClose={closeDesignBoardDrawer}
                            onClickCreateNewBoard={openCreateDesignBoardDrawer}
                        />
                    ),
                    drawerAnchor: 'right',
                },
            });
        };
        openSaveDesignBoardDrawer();

        // clean up
        setSavingItem(null);
        searchParams.delete(ADD_PIN_SEARCH_PARAM_KEY);
        setSearchParams(searchParams);
    }, [
        isLoading,
        isGuest,
        saveToBoardContext,
        savingItem,
        searchParams,
        setSavingItem,
        setSearchParams,
    ]);
};
