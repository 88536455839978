import { Tracker } from '../../types';
import { LocationState, useLocationState } from './useLocationState';
import type { Location } from 'history';

export const useLogPageView = (
    location: Location,
    trackerInstance: Tracker,
    eventData: (locationState: LocationState) => Record<string, unknown>
): ReturnType<typeof useLocationState> => {
    const logPageView = (locationState: LocationState) => {
        const data = eventData(locationState);
        void trackerInstance.log('PageView', {
            data,
            url: new URL(window.location.href),
        });
    };

    return useLocationState(location, locationState => {
        logPageView(locationState);
    });
};

/**
 * A util function be used alongside the page view logs.
 * This is left external to avoid polluting the main usage of the useLogPageView hook
 * and is intended to be injected in as a dependency instead.
 *
 * This code was migrated from manager, with slight modification.
 */
export function makePageViewTrackingData({
    url,
    previousUrl,
}: LocationState): Record<string, unknown> {
    const libraryDetailPage = [
        '/product/',
        '/professional/',
        '/project/',
        '/article/',
        '/digital-magazine/',
    ];

    let data = {
        libraryPage: libraryDetailPage.some(path => url.startsWith(path))
            ? ('1' as const)
            : ('0' as const),
        referrer: previousUrl,
    };

    const retailerItemIdElement = document.querySelector(
        'meta[property="product:retailer_item_id"]'
    );

    if (retailerItemIdElement) {
        data = Object.assign(data, {
            content_ids: JSON.stringify([
                retailerItemIdElement.getAttribute('content'),
            ]),
            content_type: 'product',
        });
    }

    return data;
}
