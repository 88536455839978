import { useLatest, useSafeState } from 'ahooks';
import { useEffect } from 'react';
export interface UseActivitySensorOpts {
    debounceMs?: number;
}

/**
 * Very basic implementation of an activity hook that we can use
 * when polling. Eventually this can be made smarter and use other events like
 * mouse events to detect if a user is not active on the page but it's still in the
 * foreground
 */
function useActivitySensor(opts: UseActivitySensorOpts = {}): boolean {
    const { debounceMs = 0 } = opts;
    const [active, setActive] = useSafeState(true);
    // Needed for the timeout to get the correct value from memory
    const activeRef = useLatest(active);

    useEffect(() => {
        const onBlur = () => {
            if (debounceMs) {
                setTimeout(() => {
                    if (setActive && activeRef.current) {
                        setActive(false);
                    }
                }, debounceMs);
            } else {
                setActive(false);
            }
        };

        const onFocus = () => {
            setActive(true);
        };

        window.addEventListener('blur', onBlur);
        window.addEventListener('focus', onFocus);

        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, [debounceMs, activeRef, setActive]);

    return active;
}

export default useActivitySensor;
