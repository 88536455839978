import {
    Button,
    productTheme,
    Provider,
    useStyles,
} from '@archipro-design/aria';
import * as S from './ShoppingCartPopup.style';
import CartHeader from './CartHeader';
import CartItemList from './CartItemList';
import CartSummary from './CartSummary';
import { Cart } from '../../types';
import { useTracker } from '@archipro-website/tracker';

interface ShoppingCartPopupProps {
    cart: Cart;
}
const ShoppingCartPopup = ({ cart }: ShoppingCartPopupProps): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles();

    const tracker = useTracker();

    const { LastAddedItems = [], ItemTotal = { ShortFormat: '' } } = cart;

    const buttonDisabled = !LastAddedItems.length;

    return (
        <Provider theme={productTheme}>
            <div className={css(S.ShoppingCartPopupContent)}>
                <CartHeader />
                <div>
                    <CartItemList items={LastAddedItems || []} />
                </div>
                <CartSummary amount={ItemTotal || { ShortFormat: '' }} />
                <div>
                    {buttonDisabled ? (
                        <Button disabled={true} size={20} fluid>
                            GO TO CART
                        </Button>
                    ) : (
                        <Button
                            as={'a'}
                            href={'/shopping-cart'}
                            size={20}
                            fluid
                            onClick={() => {
                                void tracker.log('GoToCartButtonClick', {
                                    url: new URL(window.location.href),
                                    targetTracker: 'archiproTracker',
                                    data: {
                                        ExtraData: cart.ID,
                                    },
                                });
                            }}
                        >
                            GO TO CART
                        </Button>
                    )}
                </div>
            </div>
        </Provider>
    );
};

export default ShoppingCartPopup;
