export const getBMPagePath = (
    companyURLSegment?: string,
    ...paths: string[]
): string => {
    const bmBasePath = `/business`;
    if (!companyURLSegment) {
        return bmBasePath;
    }

    const defaultRoute = '/';
    const cleanedPaths = paths.filter(i => !!i);
    const extraPaths = cleanedPaths.length
        ? '/' + cleanedPaths.join('/')
        : defaultRoute;

    const noTrailingSlashPaths = extraPaths.endsWith('/')
        ? extraPaths.slice(0, -1)
        : extraPaths;

    return `${bmBasePath}/${companyURLSegment}${noTrailingSlashPaths}`;
};
