import { makeRenderAnchorTagFn } from '@archipro-website/top-navigation';
import { FooterNavigation as Footer } from '@archipro-website/top-navigation';
import { useCountryContext } from '@archipro-website/localisation';
import { Link, useFetcher, useLocation } from '@remix-run/react';
import type { SubscriptionAction } from '~/routes/remix-api.user.subscription';
import { useMemo } from 'react';

import FooterSubscription from '~/modules/navigation/component/footer-navigation/FooterSubscription';
import { useABTestIsOn } from '@modules/root/hook/use-growthbook';
import { hasFeature, useAppData } from '~/modules/root';
import { useAppDisplayModeContext, useStyles } from '@archipro-design/aria';
import MonthlyUserCounter, {
    FOOTER_COUNTER_MINIMUM_THRESHOLD,
} from '@modules/navigation/component/footer-navigation/MonthlyUserCounter';
import * as S from './FooterNavigation.style';

interface FooterNavigationProps {
    remixEnabled?: boolean;
    showGuestNav?: boolean;
    displayUserCounter?: boolean;
    monthlyUserCount: number;
}

const FooterNavigation = ({
    remixEnabled,
    showGuestNav,
    displayUserCounter,
    monthlyUserCount,
}: FooterNavigationProps) => {
    const { code } = useCountryContext();
    const fetcher = useFetcher<SubscriptionAction>();
    const appDisplayMode = useAppDisplayModeContext();
    const guestHomeABTest = useABTestIsOn('guest-homepage');

    const { user } = useAppData();
    const footerUpdate = hasFeature(user.FeatureFlags, 'footer_update');

    const showFooterSubscription =
        (showGuestNav && guestHomeABTest) || footerUpdate;

    const showCounter =
        displayUserCounter &&
        monthlyUserCount > FOOTER_COUNTER_MINIMUM_THRESHOLD;
    const userCounter = showCounter && (
        <MonthlyUserCounter count={monthlyUserCount} />
    );
    const { css } = useStyles({ showCounter });

    const location = useLocation();
    const error =
        fetcher.data && 'error' in fetcher.data && fetcher.data.message;
    const success = !!(
        fetcher.data &&
        'success' in fetcher.data &&
        fetcher.data.data
    );

    const renderAnchorTag = useMemo(() => {
        return makeRenderAnchorTagFn(
            'web',
            {
                linkComponent: Link,
            },
            remixEnabled
        );
    }, [remixEnabled]);

    const onSubmit = (email: string): void => {
        fetcher.submit(
            { email },
            {
                method: 'post',
                action: '/remix-api/user/subscription',
            }
        );
    };

    return (
        <div className={css(S.FooterContainer)}>
            <Footer
                countryCode={code}
                renderAnchorTag={renderAnchorTag}
                location={location}
                appDisplayMode={appDisplayMode}
                userCounter={userCounter}
                subscription={{
                    formElement: fetcher.Form,
                    error: error ? error : undefined,
                    success,
                    onSubmit: (email) => onSubmit(email),
                }}
                footerSubscription={
                    showFooterSubscription ? (
                        <FooterSubscription
                            formElement={fetcher.Form}
                            onSubmit={onSubmit}
                            error={error ? error : ''}
                            success={success}
                            loading={fetcher.state !== 'idle'}
                        />
                    ) : undefined
                }
            />
        </div>
    );
};

export default FooterNavigation;
