import {
    AppDisplayModeType,
    boxClassName,
    ComponentEventHandler,
    inputSlotClassNames,
    pxToRem,
    TopNavigationProps,
} from '@archipro-design/aria';
import { RefObject } from 'react';
import { TopNavSearchItem } from '../types';
import { CloseLine, SearchLine } from '@archipro-design/icons';
import type { Location } from 'react-router-dom';

interface SearchBarSlotOpts {
    searchValue: string;
    onChange: ComponentEventHandler<{
        item: TopNavSearchItem;
        type: 'searchType' | 'searchClick';
    }>;
    onClose: ComponentEventHandler<never>;
    onCancel?: ComponentEventHandler<never>; // optional only for mobile
    onFocus: ComponentEventHandler<never>;
    ref: RefObject<HTMLElement>;
    inputRef?: RefObject<HTMLInputElement>;
    appDisplayMode: AppDisplayModeType;
    location: Location;
    showSearchBar: boolean;
    showSearchMenu: boolean;
}

export function searchBarSlot({
    searchValue,
    onChange,
    onClose,
    onCancel,
    onFocus,
    ref,
    inputRef,
    appDisplayMode,
    location,
    showSearchBar,
    showSearchMenu,
}: SearchBarSlotOpts): TopNavigationProps['searchBar'] {
    const handleSearchType = (
        event: Parameters<SearchBarSlotOpts['onChange']>[0]
    ): void => {
        const searchValue = (event.target as HTMLInputElement).value;
        onChange(event, { item: { search: searchValue }, type: 'searchType' });
    };

    const handleSearchClick = (
        event: Parameters<SearchBarSlotOpts['onChange']>[0],
        item: TopNavSearchItem
    ) => {
        onChange(event, {
            item,
            type: 'searchClick',
        });
    };

    return {
        ref,
        key: 'searchBar',
        searchInput: {
            as: 'form',
            action: '', // fix virtual keyboard popup says return instead of search in ios
            id: 'searchbar-input',
            ref: inputRef,
            autoComplete: 'off',
            type: 'search',
            value: searchValue,
            ...(showSearchBar && {
                autoFocus: true,
            }),
            onChange: e => {
                handleSearchType(e);
            },
            onFocus: e => {
                onFocus(e);
            },
            onKeyDown: e => {
                if (e.key === 'Enter') {
                    if (searchValue) {
                        handleSearchClick(e, { search: searchValue });
                    }
                }
            },
            variables: {
                borderWidth: 0,
                fontSize: appDisplayMode.desktop ? pxToRem(16) : pxToRem(18),
            },
            ...(appDisplayMode.mobile && {
                styles: {
                    [`& > div.${boxClassName} > input.${inputSlotClassNames.input}`]:
                        {
                            height: 'unset',
                        },
                },
            }),
        },
        iconButtonBefore: undefined,
        iconButtonAfter: {
            onClick: e => {
                if (!showSearchBar && !searchValue) return;
                onClose(e);
            },
            icon: !searchValue ? <SearchLine /> : <CloseLine />,
            size: appDisplayMode.desktop ? 18 : 24,
            variables: { buttonPadding: appDisplayMode.desktop ? 8 : 0 },
            ...(appDisplayMode.mobile && {
                styles: {
                    '& > span': {
                        paddingRight: pxToRem(8),
                    },
                },
            }),
        },

        cancelButton:
            appDisplayMode.mobile && showSearchMenu
                ? {
                      children: 'Cancel',
                      size: 16,
                      variables: {
                          buttonPadding: 0,
                      },
                      design: {
                          marginLeft: pxToRem(15),
                          marginRight: 0,
                      },
                      onClick: e => {
                          onCancel?.(e);
                          e.stopPropagation();
                      },
                  }
                : undefined,
        variables: appDisplayMode.mobile
            ? {
                  searchBarBorderRadius: pxToRem(4),
              }
            : {
                  searchBarPadding: '0',
                  searchBarBorderRadius: pxToRem(4),
                  searchBarBackgroundColor: 'transparent',
                  searchBarBorderColor: location.pathname.includes(
                      'ecommerce-launch'
                  )
                      ? '#9B9B9B'
                      : '#d2d2d0',
                  searchInputPaddingX: pxToRem(14),
              },
    };
}
