import type {
    OnDeployEvent,
    OnDeployment,
    Unregister,
} from './useDeploymentShouldReload';

import * as Webpush from '@archipro/webpush-client';

interface WebpushConfig {
    appId: string;
    channelName: string;
    eventName: string;
    onDeployment: OnDeployment;
}

export const webpushObserver = (config: WebpushConfig): Unregister => {
    const { onDeployment, appId, channelName, eventName } = config;
    const client = new Webpush.Client({
        appId,
    });

    client.connect();
    const callback = ({ data }: { data: OnDeployEvent }) => {
        onDeployment(data);
    };
    const channel = client.subscribe(channelName);
    channel.bind<typeof config.eventName, { data: OnDeployEvent }>(
        eventName,
        callback
    );
    return () => {
        channel.unbind(eventName, callback);
        client.disconnect();
    };
};
