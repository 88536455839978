import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';

export const BackgroundContainer: StyleRule = ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    left: 0,
    overflowY: 'auto',
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: theme.siteVariables.zIndexes.topNav - 1,
    background:
        '#000 url(/assets/website/images/ui/page_403_background.png) no-repeat fixed top',
    backgroundSize: 'cover',

    [theme.screen.laptop]: {
        zIndex: theme.siteVariables.zIndexes.overlayPriority,
    },
});

export const MainContent: StyleRule = ({ theme }) => ({
    flexGrow: 1,
    marginTop: pxToRem(56),

    [theme.screen.laptop]: {
        flexGrow: 0,
        marginTop: pxToRem(112),
    },
});

export const Logo: StyleRule = ({ theme }) => ({
    margin: pxArrayToRem([48, 0, 48, 0]),

    '& svg': {
        width: pxToRem(240),
        height: pxToRem(49.81),
        fill: theme.siteVariables.colors.white['FFF'],
    },
});

export const Footer: StyleRule = ({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, auto)',
    gridGap: pxToRem(60),
    margin: pxArrayToRem([33, 0, 33, 0]),

    '& a': {
        padding: 0,
        color: theme.siteVariables.colors.white['000'],
        ':hover': {
            textDecoration: 'underline',
        },
    },
});

export const BottomPart: StyleRule = () => ({
    flexGrow: 1,
});
