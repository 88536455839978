import type { AppDisplayModeType, LogoAnimation } from '@archipro-design/aria';
import { useBoolean, useIsomorphicLayoutEffect, usePrevious } from 'ahooks';
import { useEffect, useState } from 'react';
import { TopNavTransitionState } from '../types';
import { MegaMenuState } from './useMegaMenu';
import { Location } from 'history';
import { isSearchPageActive, PARAM_SEARCH_QUERY_KEY } from './useSearchProps';

interface UseLogoAnimationTransitionProps {
    disableSlideAnimation: boolean;
    defaultFullLogo: boolean;
    topNavState: TopNavTransitionState;
    appDisplayMode: AppDisplayModeType;
    location: Location;
    megaMenuState?: MegaMenuState;
    showPersonalMenu: boolean;
}

export const useLogoAnimationTransition = ({
    disableSlideAnimation,
    defaultFullLogo,
    topNavState,
    appDisplayMode,
    location,
    megaMenuState,
    showPersonalMenu,
}: UseLogoAnimationTransitionProps) => {
    const [showFullLogo, showFullLogoActions] = useBoolean(defaultFullLogo);
    const [logoAnimation, setLogoAnimation] = useState<
        LogoAnimation | undefined
    >(undefined);
    const prevTopNavState = usePrevious(topNavState);
    const isHomePage =
        location.pathname.startsWith('/welcome') || location.pathname === '/';

    useIsomorphicLayoutEffect(() => {
        if (appDisplayMode.desktop) {
            if (defaultFullLogo) {
                //show fulllogo for default selected route
                // and no animation
                showFullLogoActions.setTrue();
                setLogoAnimation(undefined);
            } else {
                //scrolling back to top
                if (
                    topNavState === 'static' &&
                    prevTopNavState === 'slide-in' &&
                    !disableSlideAnimation
                ) {
                    showFullLogoActions.setFalse();
                    setLogoAnimation('eraseOut');
                }
                // when user scroll state slide-in or slide-out
                if (topNavState !== 'static' && !disableSlideAnimation) {
                    showFullLogoActions.setTrue();
                    setLogoAnimation(undefined);
                }
            }
        } else {
            //animate the logo only if  not fulllogo
            if (!showFullLogo) {
                if (
                    topNavState === 'static' &&
                    prevTopNavState === 'slide-in' &&
                    !disableSlideAnimation
                ) {
                    setLogoAnimation('scaleUp');
                }
                if (topNavState !== 'static' && !disableSlideAnimation) {
                    setLogoAnimation('scaleDown');
                }
            }
        }
    }, [
        defaultFullLogo,
        appDisplayMode.desktop,
        disableSlideAnimation,
        prevTopNavState,
        showFullLogo,
        topNavState,
    ]);

    useIsomorphicLayoutEffect(() => {
        //megamenu interactions
        if (!defaultFullLogo && topNavState === 'static') {
            //when megaMenu is active
            if (megaMenuState === 'expanded') {
                setLogoAnimation('typing');
                showFullLogoActions.setTrue();
            }
            //when previously closed the megaMenu
            if (megaMenuState === 'collapsed') {
                setLogoAnimation('eraseOut');
                showFullLogoActions.setFalse();
            }
        }
    }, [defaultFullLogo, megaMenuState, showFullLogo, topNavState]);

    // update mobile logo base on route page
    useEffect(() => {
        if (appDisplayMode.mobile && showPersonalMenu) {
            showFullLogoActions.setTrue();
            setLogoAnimation(undefined);
            return;
        }

        // Homepage, on mobile and is floating; Show Archipro log
        if (appDisplayMode.mobile && isHomePage && topNavState !== 'static') {
            showFullLogoActions.setTrue();
            setLogoAnimation(undefined);
            return;
        }

        const searchParams = new URLSearchParams(location.search);

        // Not homepage/search and on mobile; Show Archipro log
        if (
            appDisplayMode.mobile &&
            !isHomePage &&
            (!isSearchPageActive(location) ||
                (isSearchPageActive(location) &&
                    searchParams.get(PARAM_SEARCH_QUERY_KEY)))
        ) {
            showFullLogoActions.setTrue();
            setLogoAnimation(undefined);
            return;
        }

        // homepage or search and on mobile; Show A logo
        if (
            appDisplayMode.mobile &&
            (isHomePage ||
                (isSearchPageActive(location) &&
                    !searchParams.get(PARAM_SEARCH_QUERY_KEY)))
        ) {
            showFullLogoActions.setFalse();
        }
    }, [
        appDisplayMode,
        isHomePage,
        location,
        showFullLogoActions,
        showPersonalMenu,
        topNavState,
    ]);

    return {
        showFullLogo,
        showFullLogoActions,
        logoAnimation,
        setLogoAnimation,
    };
};
