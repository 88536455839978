import type { StyleRule } from '@archipro-design/aria';
import { pxArrayToRem, pxToRem } from '@archipro-design/aria';

export const CartItemList: StyleRule = ({ theme }) => ({
    maxHeight: pxToRem(580),
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    margin: pxArrayToRem([24, 0]),
    '& li': {
        listStyle: 'none',
    },
    '&::-webkit-scrollbar': {
        width: pxToRem(6),
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.siteVariables.colors.white[300],
    },
    '&::-webkit-scrollbar-thumb:vertical': {
        backgroundColor: theme.siteVariables.colors.charcoal[250],
    },
});

export const EmptyList: StyleRule = ({ theme }) => ({
    color: theme.siteVariables.colors.grey[300],
    fontSize: theme.siteVariables.fontSizes['label02'],
    padding: pxArrayToRem([12, 16, 12, 0]),
});
