import React from 'react';
import type { Category } from '@archipro-website/top-navigation';

import { useAppData } from '@modules/root';
import type { UseCategoryTreeOpts } from '@archipro-website/top-navigation/src/lib/hooks/useCategoryTree';

type UseCategoryTreeDataResult = Pick<
    UseCategoryTreeOpts,
    'data' | 'onFetchCategoryTree'
>;

export const useCategoryTreeData = (): UseCategoryTreeDataResult => {
    const appData = useAppData();

    const [data, setData] = React.useState<UseCategoryTreeDataResult['data']>();

    const onFetchCategoryTree: UseCategoryTreeDataResult['onFetchCategoryTree'] =
        (directory, link) => {
            if (!appData.megamenu?.[directory]) return;
            const menuData = appData.megamenu[directory];
            const category = findCategoryByLink(menuData.categoryTree, link);

            if (!category) return;

            setData({
                directory: menuData.directory,
                category,
            });
        };

    return { data, onFetchCategoryTree };
};
const findCategoryByLink = (
    category: Category,
    link: string
): Category | null => {
    if (category.link === link) {
        return category;
    }

    if (category.children) {
        for (const child of category.children) {
            const found = findCategoryByLink(child, link);
            if (found) {
                return found;
            }
        }
    }

    return null;
};
