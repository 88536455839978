import type {
    AcceptedTiles,
    MegaMenuProps,
    ShorthandValue,
    FeaturedTileInstance,
} from '@archipro-design/aria';
import { FeaturedTile } from '@archipro-design/aria';
import type { RenderAnchorTag } from '@archipro-website/top-navigation';
import type { ArticleTileViewModel } from '@modules/article/type';
import { getArticleTile } from '@modules/article/util/get-article-tile';
import type { DirectoryItemTile } from '@modules/directory/type';
import type { ProductTileViewModel } from '@modules/product/type';
import { getProductTile } from '@modules/product/util/get-product-tile';
import type { ProfessionalTileViewModel } from '@modules/professionals-category/type';
import { getProfessionalTile } from '@modules/professionals-category/util/get-professional-tile';
import type { ProjectTileViewModel } from '@modules/projects-category/type';
import { getProjectTile } from '@modules/projects-category/util/get-project-tile';
import type { RefObject } from 'react';
import type { AdEventHandlerOnClick } from '@archipro-website/tracker';

export const getAdFeaturedTile = (
    item: DirectoryItemTile,
    onClick: AdEventHandlerOnClick,
    ref: RefObject<HTMLDivElement>,
    renderAnchorTag: RenderAnchorTag
): MegaMenuProps['featuredTileOverride'] => {
    const title = 'Featured';
    let instanceProps: ShorthandValue<AcceptedTiles>;

    let tileProps: FeaturedTileInstance;
    switch (item.type) {
        case 'product':
            instanceProps = getProductTile(
                item as ProductTileViewModel,
                renderAnchorTag
            );

            tileProps = { type: 'product', instanceProps };
            break;
        case 'project':
            instanceProps = getProjectTile(
                item as ProjectTileViewModel,
                renderAnchorTag
            );

            tileProps = { type: 'project', instanceProps };
            break;
        case 'professional':
            instanceProps = getProfessionalTile(
                item as ProfessionalTileViewModel,
                renderAnchorTag
            );

            tileProps = { type: 'professional', instanceProps };
            break;
        case 'article':
            instanceProps = getArticleTile(
                item as ArticleTileViewModel,
                renderAnchorTag
            );

            tileProps = { type: 'article', instanceProps };
            break;
        default:
            throw new Error('Unsupported feature tile type');
    }

    return (
        <FeaturedTile
            onClick={() => {
                onClick('Ad_CTAClick');
            }}
            ref={ref}
            title={title}
            tile={tileProps}
        />
    );
};
