import { Badge, TopNavigationProps, useStyles } from '@archipro-design/aria';
import { RenderAnchorTag } from '../types';
import { HeartLight as HeartIcon } from '@archipro-design/icons';
import * as S from './svgIconSlotOverride.style';

export function favouriteSlot(
    count = 0,
    renderAnchorTag: RenderAnchorTag
): TopNavigationProps['cartSlot'] {
    const { css } = useStyles();
    return {
        key: 'favouriteSlot',
        icon: (
            <Badge count={count} anchorVertical="top">
                <HeartIcon
                    aria-label={'favourite'}
                    className={css(S.svgIconOverride)}
                />
            </Badge>
        ),
        ...renderAnchorTag('/member/design-boards'),
    };
}
