import {
    pxToRem,
    pxArrayToRem,
    inputClassName,
    buttonClassName,
    flexClassName,
    subscriptionClassName,
    inputSlotClassNames,
    captionClassName,
    labelClassName,
} from '@archipro-design/aria';
import type { StyleRule } from '@archipro-design/aria';

export const FOOTER_BACKGROUND_DESKTOP_WIDTH = 1066;
export const FOOTER_BACKGROUND_DESKTOP_HEIGHT = 480;
export const FOOTER_BACKGROUND_MOBILE_WIDTH = 414;
export const FOOTER_BACKGROUND_MOBILE_HEIGHT = 182;

export const FooterSubscriptionContainer: StyleRule = ({ theme }) => ({
    height: pxToRem(467),
    width: '100%',
    background: '#DDDDDD',
    position: 'relative',
    [theme.screen.tablet]: {
        height: pxToRem(479),
        padding: pxArrayToRem([0, 50]),
    },
});

export const FooterSubscriptionContentContainer: StyleRule = () => ({
    height: '100%',
    width: '100%',
    maxWidth: pxToRem(1920),
    margin: 'auto',
    overflow: 'hidden',
    position: 'relative',
});

export const ImageContainer: StyleRule = ({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: pxToRem(FOOTER_BACKGROUND_MOBILE_WIDTH),
    height: pxToRem(FOOTER_BACKGROUND_MOBILE_HEIGHT),
    marginLeft: pxToRem(10),
    [theme.screen.tablet]: {
        width: pxToRem(FOOTER_BACKGROUND_DESKTOP_WIDTH),
        height: pxToRem(FOOTER_BACKGROUND_DESKTOP_HEIGHT),
        marginLeft: 'auto',
        marginRight: 0,
    },
});

export const FormContainer: StyleRule = ({ theme }) => ({
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: pxToRem(900),
    padding: pxArrayToRem([50, 18, 24, 18]),

    // style override aria
    [`& .${subscriptionClassName} .${captionClassName}`]: {
        color: theme.siteVariables.colors.primitive.black,
    },

    [`& .${subscriptionClassName} .${inputClassName} .${inputSlotClassNames.input}`]:
        {
            color: theme.siteVariables.colors.primitive.black,
            [`&::placeholder`]: {
                color: theme.siteVariables.colors.primitive.black,
            },

            [`&:-webkit-autofill`]: {
                '-webkit-box-shadow': '0 0 0 30px #DDDDDD inset !important',
            },
        },

    [theme.screen.max.tablet]: {
        [`& .${flexClassName}`]: {
            borderBottom: `1px solid ${theme.siteVariables.colors.primitive.black}`,
        },
        [`& .${subscriptionClassName} .${buttonClassName}`]: {
            padding: 0,
            margin: pxArrayToRem([0, 10, 9, 0]),

            '&:active': {
                color: theme.siteVariables.colors.primitive.white,
                backgroundColor: theme.siteVariables.colors.primitive.black,
            },
        },

        [`& .${subscriptionClassName} .${inputClassName} .${inputSlotClassNames.input}`]:
            {
                paddingBottom: pxToRem(14),
                height: pxToRem(33),
            },
    },
    [theme.screen.tablet]: {
        position: 'absolute',
        zIndex: 1,
        padding: pxArrayToRem([64, 80, 64, 0]),
        [`& .${subscriptionClassName} .${inputClassName} .${inputSlotClassNames.input}`]:
            {
                height: pxToRem(42),
                borderBottom: `1px solid ${theme.siteVariables.colors.primitive.black}`,
                paddingBottom: pxToRem(23),
                marginTop: pxToRem(21),
                fontSize: pxToRem(14),
            },
        [`& .${subscriptionClassName} .${buttonClassName}`]: {
            backgroundColor: theme.siteVariables.colors.charcoal[250],
            padding: pxArrayToRem([24, 23.5]),
            margin: pxArrayToRem([0, 0, 0, 59]),
            '&:hover': {
                backgroundColor: theme.siteVariables.colors.charcoal[260],
            },
            [`& .${labelClassName}`]: {
                ...theme.siteVariables.textStyles.GTAmericaTrialFont.Medium65,
                fontSize: pxToRem(15),
                lineHeight: pxToRem(15),
                letterSpacing: '0.11em',
            },
        },
    },
});

export const FooterTitle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    color: theme.siteVariables.colors.primitive.black,
    [theme.screen.max.tablet]: {
        maxWidth: pxToRem(218),
        letterSpacing: '0.01em',
        fontSize: pxToRem(26),
        lineHeight: pxToRem(29),
        margin: 0,
    },
    [theme.screen.tablet]: {
        maxWidth: pxToRem(350),
        letterSpacing: '0.001em',
        fontSize: pxToRem(40),
        lineHeight: pxToRem(44),
    },
});

export const FooterContent: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Text.Roman55,
    [theme.screen.max.tablet]: {
        marginTop: pxToRem(33),
        marginBottom: pxToRem(29),
        maxWidth: pxToRem(362),
        letterSpacing: '-0.01em',
        fontSize: theme.siteVariables.fontSizes.caption03,
        color: theme.siteVariables.colors.primitive.black,
        lineHeight: pxToRem(19),
        opacity: 0.5,
    },
    [theme.screen.tablet]: {
        marginTop: pxToRem(32),
        marginBottom: pxToRem(92),
        maxWidth: pxToRem(362),
        letterSpacing: '-0.01em',
        fontSize: theme.siteVariables.fontSizes.caption03,
        color: theme.siteVariables.colors.primitive.black,
        lineHeight: pxToRem(19),
    },
});
