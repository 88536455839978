import type { ErrorResponse } from '@remix-run/react';
import { useLocation } from '@remix-run/react';
import type { loader } from '~/routes/_app.$';
import NotFound from '@modules/error/component/not-found/NotFound';
import ErrorPage from '@modules/error/page/ErrorPage';
import ErrorPageWrapper from '@modules/error/component/error-page-wrapper/ErrorPageWrapper';
import { isApError } from '~/modules/root/graphql/responses';
import Page403 from '~/modules/error/component/page-403/Page403';
import Page401 from '~/modules/error/component/page-401/Page401';
import { useToaster } from '~/modules/root/context/ToasterContext';
import { useEffect } from 'react';
import { useMaybeAppData } from '~/modules/root';
import type { SerializeFrom } from '@remix-run/server-runtime';

/**
 * This component should be exported from a Page that is the outlet of
 * the root Page. It will render between top nav and footer
 */
export const CatchPage = ({ caught }: { caught: ErrorResponse }) => {
    const location = useLocation();
    const maybeAppData = useMaybeAppData();
    const user = maybeAppData?.user;

    const { toast } = useToaster();

    useEffect(() => {
        if (typeof caught.data === 'string') {
            toast(caught.data);
        }
    }, [caught.data, toast]);

    switch (caught.status) {
        case 404:
            /**
             * leap of faith... the assertResponse knows how to load the data for us.
             * We should always use that?.
             */
            const data = caught.data as SerializeFrom<typeof loader>;
            const { trendingCategories, statsNumbers } = data;
            if (!trendingCategories || !statsNumbers) {
                return (
                    <ErrorPage
                        error={
                            new Error(
                                `Cannot load 404 data at ${location.pathname}. This generally occurs when using router links instead of browser`
                            )
                        }
                    />
                );
            }
            return (
                <ErrorPageWrapper>
                    <NotFound
                        trendingCategories={trendingCategories}
                        statsNumbers={statsNumbers}
                    />
                </ErrorPageWrapper>
            );
        case 403:
            return (
                <ErrorPageWrapper>
                    <Page403 user={user} />
                </ErrorPageWrapper>
            );
        case 401:
            return (
                <ErrorPageWrapper>
                    <Page401 />
                </ErrorPageWrapper>
            );
    }

    const { status, statusText, data } = caught;

    let message: string = statusText;
    if (isApError(data)) {
        message = data.message;
    }
    message = message || 'Unknown error';

    const error = new Error(`${status}: ${message}`);

    /**
     * add special content for other error types such as
     * 500 or 403 401
     */
    return <ErrorPage error={error} />;
};
