import {
    memberTheme,
    BaseThemePrepared,
    advertisingTheme,
    aboutUsTheme,
    meetGreetTheme,
    baseTheme,
} from '@archipro-design/aria';
export const getThemeLegacyByUrl = (
    pathname: string
): BaseThemePrepared | undefined => {
    const basePath = pathname.split('/')[1] ?? 'default';

    if (basePath.startsWith('ace-')) {
        return memberTheme;
    }
    if (basePath.startsWith('home-design-event')) {
        return meetGreetTheme;
    }
    switch (basePath) {
        case 'about-us':
            return aboutUsTheme;
        case 'advertising':
            return advertisingTheme;
        case 'contact-us':
        case 'member':
        case 'checkout':
        case 'building-guide':
        default:
            return baseTheme;
    }
};
