import {
    ButtonProps,
    ComponentEventHandler,
    TopNavigationProps,
} from '@archipro-design/aria';
import { RenderAnchorTag } from '../types';

export function searchSlot(
    renderAnchorTag: RenderAnchorTag,
    renderLinks: string,
    onClick?: ComponentEventHandler<ButtonProps>
): TopNavigationProps['searchSlot'] {
    return {
        key: 'searchSlot',
        onClick,
        ...renderAnchorTag(renderLinks),
    };
}
