import { Grid, Label, pxArrayToRem, useStyles } from '@archipro-design/aria';
import * as S from './CartHeader.style';

const CartHeader = () => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles();
    return (
        <Grid columns={pxArrayToRem([296, 46])} className={css(S.CartHeader)}>
            <Label variant="01" weight="medium">
                Shopping cart
            </Label>
        </Grid>
    );
};

export default CartHeader;
