import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

/**
 *
 * @param date Date in string (i.e. 2022-03-23), should be UTC+0
 * @param utc  Set to true if you want to transform your date to UTC+0
 * @returns Dayjs date
 */

export const utcDateToLocalTimezone: (date: string) => dayjs.Dayjs = (date) => {
    const timezone = dayjs.tz.guess();
    return dayjs.utc(date).tz(timezone);
};
