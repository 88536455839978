import { DirectoryMenuItems } from '../types';

export const DEFAULT_DIRECTORY_MENU_ITEMS: DirectoryMenuItems = {
    products: {
        key: 'product',
        label: 'Products',
        labelPrefix: 'Shop',
        href: '/products',
        count: 0,
    },
    projects: {
        key: 'project',
        label: 'Projects',
        labelPrefix: 'Browse',
        href: '/projects',
        count: 0,
    },
    professionals: {
        key: 'professional',
        label: 'Professionals',
        labelPrefix: 'Connect with',
        href: '/professionals',
        count: 0,
    },
    articles: {
        key: 'article',
        label: 'Archizeen',
        labelPrefix: 'Read',
        href: '/articles',
        count: 0,
    },
};
