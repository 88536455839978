import { useNavigation, useSearchParams } from '@remix-run/react';
import type { accounts, CredentialResponse } from 'google-one-tap';
import { useFetcher } from '@remix-run/react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { useUser } from '@modules/user';
import { useCallback, useEffect } from 'react';
import { useScript } from '~/modules/root/hook/use-script';
import { assertSuccessResponse } from '~/modules/root/graphql/responses';
import { isCleanRedirect } from '~/modules/root';
import type { UserOneTapSignInAction } from '~/routes/remix-api.user.one-tap-sign-in';

// Interface for object google injected into page as the 'google' global
interface GoogleShell {
    accounts: accounts;
}

declare const google: GoogleShell | undefined;

/**
 * useOneTapSignIn:
 * @param clientId
 *
 * Note: If the popup does not show it is may be because
 * 1. g_state cookie is set which has an exponential cooldown. Delete the cookie to show the popup
 * 2. The <script/> is loaded using ClientOnly and the onLoad event is not firing properly.
 */
export const useOneTapSignIn = (clientId: string, disable = false): void => {
    const user = useUser();
    const otsFetcher = useFetcher<UserOneTapSignInAction>();
    const navigation = useNavigation();

    const status = useScript(`https://accounts.google.com/gsi/client`, {
        removeOnUnmount: false,
        shouldPreventLoad: disable,
    });

    const handleCallback = useMemoizedFn(
        (googleResponse: CredentialResponse) => {
            const { credential } = googleResponse;
            otsFetcher.submit(
                { credential },
                {
                    method: 'post',
                    action: '/remix-api/user/one-tap-sign-in',
                }
            );
        }
    );

    const isLoggedIn = user?.__typename === 'Me';
    const [promptAllowed, setPromptAllowed] = useBoolean(false);
    const [promptLoaded, setPrompt] = useBoolean(false);
    const [searchParams] = useSearchParams();

    /**
     * Side effect:
     * Full page reload after any login to refresh userId's in our GTM and GA4 trackers
     */
    useEffect(() => {
        if (
            otsFetcher.state === 'idle' &&
            otsFetcher.data &&
            assertSuccessResponse(otsFetcher.data)
        ) {
            const urlRedirect = searchParams.get('redirect');
            if (urlRedirect && isCleanRedirect(urlRedirect)) {
                window.location.href = urlRedirect;
                return;
            }
            return window.location.reload();
        }
    }, [otsFetcher, searchParams]);

    /**
     * Side effect:
     * Sets promptAllowed to true when user makes his first navigation.
     * This is used to manage the state of prompts.
     */
    useEffect(() => {
        if (navigation.state === 'loading' && !promptAllowed) {
            setPromptAllowed.setTrue();
        }
    }, [navigation.state, setPromptAllowed, promptAllowed]);

    const handleShowOTS = useCallback(() => {
        // User has blocked one tap sign in
        if (searchParams.get('blockOneTap') !== null) {
            return;
        }

        if (typeof google === 'undefined') {
            return;
        }

        if (!isLoggedIn && !promptLoaded && promptAllowed) {
            google.accounts.id.initialize({
                client_id: clientId,
                callback: handleCallback,
                use_fedcm_for_prompt: true,
            });

            google.accounts.id.prompt();
            setPrompt.setTrue();
        } else if (isLoggedIn && promptLoaded) {
            setTimeout(() => {
                setPrompt.setFalse();
                google.accounts.id.cancel();
            }, 50);
        }
    }, [
        clientId,
        handleCallback,
        isLoggedIn,
        promptLoaded,
        searchParams,
        setPrompt,
        promptAllowed,
    ]);

    useEffect(() => {
        if (disable) return;

        if (status === 'ready') {
            handleShowOTS();
        }
    }, [status, user, handleShowOTS, disable]);
};
