import {
    useStyles,
    Dialog,
    Subheader,
    Button,
    pxToRem,
    Flex,
    Label,
    Input,
    Caption,
    Grid,
} from '@archipro-design/aria';

import { CloseLine } from '@archipro-design/icons';

import React, { useRef, useEffect } from 'react';

import * as S from './AddProfessionalModal.style';

import { Form } from '@remix-run/react';
import type { FormShape } from './AddProfessionalModal.types';
import { validator } from './AddProfessionalModal.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFetcher } from '@remix-run/react';

import { useForm, Controller } from 'react-hook-form';
import type { AddProfessionalAction } from '@modules/admin';
import { getBMPagePath } from '~/modules/root/util/get-bm-page-path';
import { fetcherIsDone } from '~/utils/fetcherHelper';

export const AddProfessionalModal: React.FC<{
    onClose: () => void;
    isOpen: boolean;
}> = ({ onClose, isOpen }) => {
    const { css } = useStyles();
    const modalRef = useRef(null);

    const { control, formState, handleSubmit } = useForm<FormShape>({
        resolver: zodResolver(validator),
        mode: 'onSubmit',
    });

    const fetcher = useFetcher<AddProfessionalAction>();
    const submitting = fetcher.state === 'submitting';

    const formErrorMessage = formState.errors.title?.message;
    const hasError = Boolean(formErrorMessage);
    const shouldShowMessage = formErrorMessage && !submitting;

    const onSubmit = async (data: FormShape) => {
        fetcher.submit(data, {
            method: 'post',
            action: '/remix-api/admin/add-professional',
        });
    };

    useEffect(() => {
        if (
            fetcherIsDone(fetcher) &&
            fetcher.data?.createProfessional.URLSegment
        ) {
            window.location.href = getBMPagePath(
                fetcher.data.createProfessional.URLSegment
            );
        }
    }, [fetcher]);

    return (
        <Dialog
            ref={modalRef}
            open={isOpen}
            trapFocus={false}
            onCancel={onClose}
            header={{
                content: (
                    <Flex
                        hAlign="start"
                        className={css(S.NewProfessionalHeaderInner)}
                        gap="space-24"
                        vAlign="center"
                        space="between"
                    >
                        <Subheader
                            variant="03"
                            variables={{
                                letterSpacing: 'normal',
                            }}
                        >
                            New professional
                        </Subheader>
                        <Button
                            onClick={() => {
                                onClose();
                            }}
                            icon={<CloseLine />}
                            size={30}
                            transparent={true}
                            variables={{
                                iconPadding: 0,
                                buttonPadding: 8,
                            }}
                        />
                    </Flex>
                ),
                style: {
                    width: '100%',
                    margin: 0,
                },
            }}
            content={{
                content: (
                    <Form
                        method={'post'}
                        onSubmit={handleSubmit((data) => onSubmit(data))}
                    >
                        <Flex
                            hAlign="start"
                            className={css(S.NewProfessionalWrapper)}
                            gap="space-8"
                            column={true}
                        >
                            <Label variant="01">{`Setup a new professional profile on ArchiPro`}</Label>
                            <Flex column={true} className={css(S.FormRow)}>
                                <Grid
                                    columns={'100%'}
                                    className={css(S.FormRowInner)}
                                >
                                    <Flex column vAlign={'start'}>
                                        <Controller<FormShape>
                                            control={control}
                                            name={'title'}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    placeholder="Enter professional name"
                                                    successIndicator={{}}
                                                    className={css(S.FormInput)}
                                                />
                                            )}
                                        />
                                    </Flex>
                                </Grid>
                                {shouldShowMessage && (
                                    <Flex
                                        data-aui-validation={'valid'}
                                        hAlign={'end'}
                                        vAlign={'start'}
                                        className={css(S.FormRowMessage)}
                                    >
                                        {hasError && (
                                            <Caption
                                                variant={'03'}
                                                className={css(S.ErrorMessage)}
                                            >
                                                {formErrorMessage}
                                            </Caption>
                                        )}
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>
                        <Flex
                            vAlign="end"
                            hAlign="end"
                            column={false}
                            className={css(S.NewProfessionalFooterInner)}
                        >
                            <Button
                                size={20}
                                variables={{
                                    buttonPadding: 12,
                                    buttonMinWidth: pxToRem(135),
                                }}
                                loading={submitting}
                                disabled={hasError}
                                type={'submit'}
                                color={'dark'}
                            >
                                CREATE
                            </Button>
                        </Flex>
                    </Form>
                ),
                style: {
                    padding: 0,
                    margin: 0,
                    borderTop: 'none',
                },
            }}
            footer={{
                style: { padding: '0' },
            }}
            style={{
                overflow: 'hidden',
                width: pxToRem(800),
                borderRadius: 0,
                padding: pxToRem(48),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
        />
    );
};
