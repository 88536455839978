import {
    Caption,
    Flex,
    Grid,
    Image,
    pxToRem,
    useStyles,
} from '@archipro-design/aria';
import * as S from './CartItem.style';
import { CartItem as CartItemType } from '../../types';

export interface Props {
    item: CartItemType;
}

const CartItem = ({ item }: Props) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles();
    const imageSrc = item.Variant.Thumbnail && item.Variant.Thumbnail?.Desktop;
    return (
        <Grid
            as="li"
            className={css(S.CartItem)}
            columns={`${pxToRem(64)} 1fr ${pxToRem(32)}`}
        >
            <Image
                src={imageSrc || ''}
                alt={'item-variant'}
                width={64}
                height={64}
            />
            <div>
                <div className={css(S.ProductTitleContainer)}>
                    <Caption
                        className={css(S.ProductTitle)}
                        weight="medium"
                        variant="03"
                        maximumLines={2}
                    >
                        {item.Variant.Product.Title}
                    </Caption>
                </div>
                <Flex className={css(S.Pricing)}>
                    <Caption
                        className={css(S.Price)}
                        variant="04"
                        weight="medium"
                    >
                        {item.Price.ShortFormat}
                    </Caption>
                    {item.Sample ? (
                        <Caption
                            className={css(S.SampleTag)}
                            variant="05"
                            weight="medium"
                        >
                            SAMPLE
                        </Caption>
                    ) : (
                        <span></span>
                    )}
                </Flex>
            </div>
            <Flex vAlign="center">
                <Caption
                    weight="medium"
                    variant="04"
                    className={css(S.Quantity)}
                >
                    {`x${item.Quantity}`}
                </Caption>
            </Flex>
        </Grid>
    );
};

export default CartItem;
