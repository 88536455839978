import {
    ComponentEventHandler,
    Flex,
    TopNavigationProps,
} from '@archipro-design/aria';
import { CloseLine, CustomSearchAndMenu } from '@archipro-design/icons';

export interface UseBurgerMenuButtonSlotOpts {
    open: boolean;
    onOpenChange: ComponentEventHandler<boolean>;
}

export function useBurgerMenuButtonSlot({
    open,
    onOpenChange,
}: UseBurgerMenuButtonSlotOpts): TopNavigationProps['menuButtonSlot'] {
    return {
        key: 'menuButtonSlot',
        icon: (
            <Flex>
                {open ? (
                    <CloseLine size={'2x'} aria-label={'menu'} />
                ) : (
                    <CustomSearchAndMenu size={'2x'} aria-label={'menu'} />
                )}
            </Flex>
        ),
        onClick: e => onOpenChange(e, !open),
    };
}
