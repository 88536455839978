import {
    AppDisplayModeType,
    Button,
    TopNavigationProps,
} from '@archipro-design/aria';
import { SearchResultProps } from '@archipro-design/aria';
import { RefObject } from 'react';
import { RecentlySearched } from '../hooks/useRecentlySearched';

import type {
    onSearchClick,
    SearchNavResultItem,
    SearchNavResults,
} from '../types';
import { toTitleCase } from '../utils/stringUtils';

const RESULT_LIST_SIZE = 5;
const RESULT_LIST_CATEGORY_SIZE = 10;

interface SearchMenuSlotOpts {
    search: string;
    searchData?: SearchNavResults | null;
    recentlySearched: RecentlySearched;
    onSearchClick: onSearchClick;
    appDisplayMode: AppDisplayModeType;
    ref: RefObject<HTMLElement>;
}

type SearchCategoriesResult =
    | 'product'
    | 'professional'
    | 'project'
    | 'blogpost';

const allCategories: Array<SearchCategoriesResult> = [
    'product',
    'professional',
    'project',
    'blogpost',
];

const buildCategoriesSuggestion = (searchData: SearchNavResults) => {
    // to equally divide the categories
    // distribute each categories to suggestCategories until reach the max size
    const suggestCategories: Array<SearchNavResultItem> = [];
    for (let i = 0; i < searchData.all.length; i++) {
        if (suggestCategories.length === searchData.all.length) {
            // stop when reach max size
            break;
        } else {
            allCategories.forEach(cat => {
                if (!searchData[cat]) return;
                if (searchData[cat][i]) {
                    suggestCategories.push(searchData[cat][i]);
                }
            });
        }
    }

    return sortArrayByIType(suggestCategories);
};

const sortArrayByIType = (searchData: SearchNavResultItem[]) => {
    // sort by 'product' | 'professional' | 'project' | 'blogpost'
    return searchData.sort((a, b) => {
        const aIndex = allCategories.indexOf(a.IType as SearchCategoriesResult);
        const bIndex = allCategories.indexOf(b.IType as SearchCategoriesResult);
        return aIndex - bIndex;
    });
};

export const searchMenuSlot = ({
    search,
    searchData,
    recentlySearched,
    onSearchClick,
    ref,
}: SearchMenuSlotOpts): TopNavigationProps['searchMenu'] => {
    return {
        ref,
        matchValue: search,
        searchResults: null,
        searchResultsAdditional:
            search !== ''
                ? searchData?.all.length
                    ? getCategories(searchData, onSearchClick)
                    : null
                : recentlySearched.recentlySearched.length
                ? getRecentlySearched(recentlySearched, onSearchClick)
                : null,
    };
};

function getCategories(
    searchData: SearchNavResults,
    onClick: onSearchClick
): SearchResultProps {
    return {
        category: 'Suggested Categories',
        items: buildCategoriesSuggestion(searchData)
            ?.slice(0, RESULT_LIST_CATEGORY_SIZE)
            ?.map((item: SearchNavResultItem, i) => {
                let itemCategoryNormalised = '';
                if (item.IType) {
                    itemCategoryNormalised =
                        item.IType === 'blogpost'
                            ? 'Archizeen'
                            : `${toTitleCase(item.IType)}s`;
                }
                return {
                    key: `category-${i}`,
                    item: item.CategoryTitle,
                    category: itemCategoryNormalised,
                    button: {
                        onClick: e =>
                            onClick(e, {
                                search: item.CategoryTitle,
                                category: item.IType,
                                categoryLink: item.Link,
                            }),
                    },
                };
            }),
    };
}

function getRecentlySearched(
    recentlySearched: Required<RecentlySearched>,
    onClick: onSearchClick
): SearchResultProps {
    const ActionButton = (
        <Button
            transparent={true}
            size={14}
            variables={{ buttonPadding: 0 }}
            onClick={() => {
                recentlySearched.clearHistory();
            }}
        >
            Clear History
        </Button>
    );
    return {
        category: 'Recently searched',
        action: ActionButton,
        items:
            recentlySearched.recentlySearched
                .slice(0, RESULT_LIST_SIZE)
                ?.map((search, i) => {
                    return {
                        key: `recently-searched-${i}`,
                        item: search,
                        button: {
                            onClick: e =>
                                onClick(e, {
                                    search,
                                }),
                        },
                    };
                }) ?? [],
    };
}
