import { Tracker } from '@archipro-website/tracker';
import { useEffect } from 'react';
import { PARAM_SEARCH_QUERY_KEY } from './useSearchProps';

export const useTrackerOnUrlParamSearchChanges = (
    urlParams: URLSearchParams,
    tracker: Pick<Tracker, 'log'>
) => {
    const updatedParams = urlParams.get(PARAM_SEARCH_QUERY_KEY);

    useEffect(() => {
        if (updatedParams) {
            tracker.log('PerformSearch', {
                url: new URL(window.location.href),

                data: {
                    ExtraData: updatedParams,
                },
            });

            // upload search event to GA4.
            tracker.log('ViewSearchResults', {
                url: new URL(window.location.href),
                targetTracker: 'ga4Tracker',
                ga4Data: {
                    search_term: updatedParams,
                },
            });
        }
    }, [updatedParams]);
};
