import React from 'react';
import { Button } from '@archipro-design/aria';
import { useCountryContext } from '@archipro-website/localisation';

const POLICY_LINKS = {
    AU: 'https://email.archipro.com.au/archipro-privacy-and-data-policy-aus',
    NZ: 'https://email.archipro.co.nz/archipro-privacy-and-data-policy-nz',
};

const FooterLinks: React.FC = () => {
    const { byCountry } = useCountryContext();

    return (
        <>
            <Button
                color="white"
                transparent={true}
                as="a"
                href={byCountry(POLICY_LINKS)}
                size={18}
            >
                Privacy Policy
            </Button>

            <Button
                color="white"
                transparent={true}
                as="a"
                href="/advertising"
                size={18}
            >
                Advertise With Us
            </Button>

            <Button
                color="white"
                transparent={true}
                as="a"
                href="/contact-us"
                size={18}
            >
                Contact Us
            </Button>
        </>
    );
};

export default FooterLinks;
