import {
    productTheme,
    BaseThemePrepared,
    ecomLandingTheme,
} from '@archipro-design/aria';
import { Directory } from '../types';

export const getThemeByHoverNav = (
    navType?: Directory | 'ecommerce-launch'
): BaseThemePrepared | undefined => {
    switch (navType) {
        case 'project':
        case 'professional':
        case 'article':
        case 'product':
            return productTheme;
        case 'ecommerce-launch':
            return ecomLandingTheme;
        default:
            return undefined;
    }
};
