import { AppDisplayModeType, PersonalMenuProps } from '@archipro-design/aria';
import { useCountryContext } from '@archipro-website/localisation';
import { useLatest } from 'ahooks';
import { useMemo } from 'react';
import {
    Notifications,
    RenderAnchorTag,
    TopNavFeatureFlags,
    User,
} from '../types';
import { createBusinessProfileProps } from '../utils/createBusinessProfileProps';
import { createPersonalMenuProps } from '../utils/createPersonalMenuProps';
import { createPersonalProfileProps } from '../utils/createPersonalProfileProps';
import { TopNavFeatures } from '../TopNavigation';

export function useCreatePersonalMenuProps(opts: {
    renderAnchorTag: RenderAnchorTag;
    user: User;
    features: TopNavFeatures;
    notifications?: Notifications | null;
    onClosePopup: () => void;
    appDisplayMode: AppDisplayModeType;
    featureFlags?: TopNavFeatureFlags;
}): PersonalMenuProps {
    const countryCtx = useCountryContext();

    const {
        renderAnchorTag,
        user,
        notifications,
        features,
        appDisplayMode,
        featureFlags,
    } = opts;

    const onClosePopupRef = useLatest(opts.onClosePopup);

    return useMemo(() => {
        return {
            personalProfile:
                user?.__typename === 'Me'
                    ? createPersonalProfileProps(
                          user,
                          renderAnchorTag,
                          appDisplayMode
                      )
                    : undefined,
            personalProfileMenu: createPersonalMenuProps({
                renderAnchorTag,
                onClosePopup: onClosePopupRef.current,
                canCreateProfessional: features.canCreateProfessional,
                user,
                notifications,
                appDisplayMode,
                featureFlags,
            }),
            businessProfiles:
                user?.__typename === 'Me'
                    ? createBusinessProfileProps({
                          renderAnchorTag,
                          user,
                          notifications,
                          countryCtx,
                          appDisplayMode,
                          featureFlags,
                      })
                    : undefined,
        };
    }, [
        countryCtx,
        notifications,
        onClosePopupRef,
        renderAnchorTag,
        user,
        features.canCreateProfessional,
        appDisplayMode,
        featureFlags,
    ]);
}
