const getServerVersion = async (): Promise<string | undefined> => {
    try {
        const manifest = window.__remixManifest.entry.module;
        const buildPath = manifest?.match(/(.*)[/\\]/)?.[1];
        const ARCHIPRO_VERSION_PATH =
            `${buildPath}/version.json?t=${Date.now()}` + Date.now();
        const response = await fetch(ARCHIPRO_VERSION_PATH);
        if (response.ok) {
            // Might want to consider removing and just relying on the header?
            const data = await response.json();
            return data?.version;
        }
    } catch {
        return;
    }
    return undefined;
};

export { getServerVersion };
