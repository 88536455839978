import Cookies from 'js-cookie';
import type { SupportedCountry } from '@archipro-website/localisation';

export const getUserLocation = (): string | undefined => {
    const location = Cookies.get('__ap_location') || '';
    const isValidLocationCookie = location.includes('|');

    let country: string | undefined = undefined;
    if (isValidLocationCookie) {
        const splitLocations = location.split('|');
        country = splitLocations.shift();
    }

    return country ? country : undefined;
};

export const getSiteLang = (country: SupportedCountry): string => {
    switch (country) {
        case 'NZ':
            return 'en-nz';
        case 'AU':
            return 'en-au';
        default:
            throw new Error(
                `Unknown country provided to getSiteLang: ${country}`
            );
    }
};

export const getSiteUrl = (country: SupportedCountry): string => {
    const url = new URL(document.URL);

    let newHost = '';
    switch (country) {
        case 'NZ':
            newHost = 'archipro.co.nz';
            break;
        case 'AU':
            newHost = 'archipro.com.au';
            break;
        default:
            break;
    }

    if (!newHost) {
        throw new Error(`Unknown country provided to getSiteUrl: ${country}`);
    }

    if (url.protocol === 'http:') {
        // must be local dev no change
        return document.URL;
    } else if (!url.hostname.includes('archipro')) {
        throw new Error(`Unsupported url provided to getSiteUrl: ${url.href}`);
    }

    url.hostname = url.hostname.split('archipro')[0] + newHost;

    return url.href;
};
