import { Flex, Label, useStyles } from '@archipro-design/aria';
import { CurrencyAmount } from '../../types';
import * as S from './CartSummary.style';

interface CartSummaryProps {
    amount: Pick<CurrencyAmount, 'ShortFormat'>;
}

const CartSummary = ({ amount }: CartSummaryProps) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { css } = useStyles();
    return (
        <Flex vAlign="center" className={css(S.CartSummary)}>
            <Label variant="02" weight="medium">
                Subtotal:
            </Label>
            <Label variant="02" weight="medium">
                {amount.ShortFormat}
            </Label>
        </Flex>
    );
};

export default CartSummary;
