import type { StyleRule } from '@archipro-design/aria';
import { captionClassName } from '@archipro-design/aria';
import { SubheaderClassName } from '@archipro-design/aria';
import { pxArrayToRem } from '@archipro-design/aria';
import { labelClassName } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const AccessNotice: StyleRule = ({ theme }) => ({
    background: theme.siteVariables.colors.white['100A'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    width: '100%',
    marginLeft: 0,
    borderRadius: 0,
    height: 'unset',
    minHeight: '100%',
    margin: '0 auto',
    padding: pxToRem(18),

    [`& > .${captionClassName}`]: {
        color: theme.siteVariables.colors.charcoal[250],
    },
    [`& > .${SubheaderClassName}`]: {
        color: theme.siteVariables.colors.charcoal[250],
    },

    [theme.screen.laptop]: {
        width: pxToRem(574),
        height: pxToRem(382),
        minHeight: 'unset',
        padding: pxToRem(28),
        marginBottom: pxToRem(221),
    },
});

export const Title: StyleRule = () => ({
    paddingBottom: pxToRem(24),
});

export const MainText: StyleRule = ({ theme }) => ({
    textAlign: 'center',
    padding: pxArrayToRem([0, 22, 24, 17]),

    [theme.screen.laptop]: {
        padding: pxArrayToRem([0, 0, 24, 0]),
    },
});

export const AvatarAndEmail: StyleRule = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: pxToRem(44),
    [`& .${SubheaderClassName}`]: {
        color: theme.siteVariables.colors.white['FFF'],
    },
    [`& .${captionClassName}`]: {
        color: theme.siteVariables.colors.primitive.black,
    },
});

export const SwitchButton: StyleRule = () => ({
    width: '100%',
    height: pxToRem(50),
    marginTop: pxToRem(2),
    marginBottom: pxToRem(28),
    [`& > div.${labelClassName}`]: {
        fontSize: pxToRem(18),
        lineHeight: 1,
    },
});

export const EmailAddress: StyleRule = () => ({
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: pxToRem(16),
});
