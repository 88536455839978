import { z } from 'zod';

interface WordsByLabelArgs {
    authorName?: string;
    byArchiPro: boolean;
    professionalName?: string;

    categoryInfo?: WordsByLabelCategoryInfo;
}

export interface WordsByLabelCategoryInfo {
    URLSegment: string;
    Parent?: { URLSegment: string };
}

export const WordsByLabelCategoryInfoSchema = z.object({
    URLSegment: z.string({
        required_error: 'WordsByLabelCategoryInfo.URLSegment is required',
    }),
    Parent: z
        .object({
            URLSegment: z.string({
                required_error:
                    'WordsByLabelCategoryInfo.Parent.URLSegment is required',
            }),
        })
        .optional(),
});

const ARCHIPRO_EDITOR_LABEL = `ArchiPro Editorial Team`;
/* 
This function has been duplicated at /projects/archipro-website/themes/archipro2/javascript/react/layout/TopNavigation/components/TopNavigationAria/getFeaturedTile.tsx
and must be kept in sync
*/
export const getWordsByLabel: (args: WordsByLabelArgs) => string = (args) => {
    const { authorName, byArchiPro, professionalName, categoryInfo } = args;
    const leafCategory = categoryInfo?.URLSegment?.trim();
    const rootCategory = categoryInfo?.Parent?.URLSegment?.trim() ?? undefined;

    if (rootCategory && leafCategory) {
        return `${rootCategory}/${leafCategory}`.toUpperCase();
    }

    if (leafCategory) {
        return leafCategory.toUpperCase();
    }

    return authorName
        ? authorName
        : byArchiPro
        ? ARCHIPRO_EDITOR_LABEL
        : professionalName ?? '';
};
