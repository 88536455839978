import { useLatest } from 'ahooks';
import { BroadcastChannel } from 'broadcast-channel';
import React, { useMemo } from 'react';
const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

type HashValue = unknown | undefined;
type CallbackType = (oldHash: string, newHash: string) => void;
interface Props {
    hashKey: string;
    value: HashValue;
    callback: CallbackType;
}

const makeSha1 = async (value: HashValue): Promise<string | undefined> => {
    if (!value) {
        return;
    }
    const enc = new TextEncoder();
    const hash = await crypto.subtle.digest(
        'SHA-1',
        enc.encode(JSON.stringify(value))
    );
    return Array.from(new Uint8Array(hash))
        .map(v => v.toString(16).padStart(2, '0'))
        .join('');
};

interface Message {
    hash: string;
    uuid: string;
}
/**
 * This hooks uses a BroadcastChannel to post a message for a given key.
 * The channel name is comprised of the the domain and the haskKey
 *
 * When the sha1 of the give value changes in any tab the callback will be invoked
 */
export default function useObjectChange(
    { hashKey, value, callback }: Props,
    deps: React.DependencyList
) {
    const hashRef = React.useRef<string | undefined>(undefined);
    const channelRef = React.useRef<BroadcastChannel<Message> | undefined>(
        undefined
    );
    const isReloadingRef = React.useRef(false);
    const uuid = React.useState(`${new Date().getTime()}`)[0];
    const cb = useLatest(callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const latestValue = useMemo(() => value, [...deps]);

    React.useEffect(() => {
        const channelName = `${window.location.hostname}:${hashKey}`;
        const channel = new BroadcastChannel<Message>(channelName);
        const listener = (message: Message) => {
            if (inIframe()) {
                return;
            }
            if (isReloadingRef.current) {
                return;
            }
            const currentHash = hashRef.current;
            if (!currentHash) {
                return;
            }

            if (message.uuid === uuid) {
                return;
            }

            if (message.hash === currentHash) {
                return;
            }
            /**
             * set the hashRef to the new value so that we don't
             * call the callback again if the value doesn't change
             */
            isReloadingRef.current = true;

            console.log(
                `%c${hashKey} has changed`,
                'background: #222; color: #bada55; padding: 5px; border-radius: 5px;'
            );
            cb.current(currentHash, message.hash);
        };
        channel.addEventListener('message', listener);
        channelRef.current = channel;
        return () => {
            channelRef.current = undefined;
            channel.removeEventListener('message', listener);
            channel.close();
        };
    }, [hashKey, hashRef, cb, isReloadingRef, uuid]);

    React.useEffect(() => {
        if (inIframe()) {
            return;
        }
        makeSha1(latestValue).then(hash => {
            if (isReloadingRef.current) {
                return;
            }
            if (!hash) {
                return;
            }
            if (hashRef.current === hash) {
                return;
            }
            hashRef.current = hash;
            channelRef.current?.postMessage({ hash, uuid });
        });
    }, [latestValue, hashRef, isReloadingRef, uuid]);
}
