import { useEffect, useState } from 'react';

let hydrating = true;

// https://github.com/sergiodxa/remix-utils/blob/main/src/react/use-hydrated.ts
export function useHydrated() {
    const [hydrated, setHydrated] = useState(() => !hydrating);

    useEffect(function hydrate() {
        hydrating = false;
        setHydrated(true);
    }, []);

    return hydrated;
}
