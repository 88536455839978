import { useSearchParams } from '@remix-run/react';
import { AddProfessionalModal } from '@modules/admin/component/add-professional-modal/AddProfessionalModal';

import { useClickBack } from '@modules/root/hook/use-click-back';
import { MODAL_KEYS } from '@archipro-website/top-navigation';
import LoginSignUpModal from '~/modules/user/component/login-signup-modal/LoginSignUpModal';
import { ClientOnly } from 'remix-utils';

const SiteWideModals = () => {
    const [searchParams] = useSearchParams();
    const { onClick: goBack } = useClickBack();

    const dismiss = () => {
        goBack();
    };

    return (
        <ClientOnly fallback={''}>
            {() => (
                <>
                    {searchParams.has(MODAL_KEYS.NEW_PROFESSIONAL) && (
                        <AddProfessionalModal
                            onClose={() => dismiss()}
                            isOpen={true}
                        />
                    )}
                    {searchParams.has(MODAL_KEYS.LOGIN) && (
                        <LoginSignUpModal
                            login={{
                                open: true,
                                onCancel: dismiss,
                                onConfirm: dismiss,
                                onAuthSuccess: dismiss,
                                flow: null,
                                authSource: 'loginPage',
                            }}
                            forgotPassword={{
                                open: true,
                                onCancel: dismiss,
                            }}
                        />
                    )}
                </>
            )}
        </ClientOnly>
    );
};

export default SiteWideModals;
