import {
    Outlet,
    isRouteErrorResponse,
    useLocation,
    useMatches,
    useNavigation,
    useRouteError,
} from '@remix-run/react';
import { CatchPage, ServerErrorPage } from '@modules/error';
import FooterNavigation from '@modules/navigation/component/footer-navigation/FooterNavigation';
import type { SerializeFrom } from '@remix-run/node';
import {
    makePageViewTrackingData,
    useLogPageView,
    useTracker,
    useLogLinkClicks,
} from '@archipro-website/tracker';
import {
    CartPopupProvider,
    useCartPopupState,
} from '@modules/ecommerce/context/CartPopupContext';
import { useHideNavigation } from '@modules/root/hook/use-hide-navigation';
import type { TopNavigationData } from '@modules/navigation/component/top-navigation/TopNavigation';
import TopNavigation from '@modules/navigation/component/top-navigation/TopNavigation';
import {
    useAppData,
    useMatchesHandleData,
    useMaybeAppData,
    useRootData,
} from '@modules/root';
import { useConfigContext } from '@archipro-website/config/bindings/react';
import { useOneTapSignIn } from '~/modules/user/hook/use-one-tap-sign-in';
import React from 'react';
import DesignBoardInitializer from '~/modules/root/component/design-board/DesignBoardInitializer';
import CountrySuggestModal from '@modules/root/component/country-suggest-modal/CountrySuggestModal';

import SiteWideModals from '@modules/root/component/site-wide-modals/SiteWideModals';
import { ToasterProvider, useToaster } from '../context/ToasterContext';
import { useEnquirySession } from '~/modules/enquiry/hook/use-enquiry-session';
import { SaveToDesignBoardProvider } from '~/modules/design-board/component/context/SaveToDesignBoardContext';
import { useOnObjectChange } from '@archipro-website/react-hooks';
import { useDeploymentShouldReload } from '@archipro-website/react-hooks';
import { getServerVersion } from '../util';
import { useLatest } from 'ahooks';
import { useABTestIsOn } from '../hook/use-growthbook';
import { Flex, ImageConfigProvider, useStyles } from '@archipro-design/aria';
import * as S from '../component/app-page-layout/AppPageLayout.style';
import { imageConfig } from '~/modules/images';
import { useAuthEvent } from '../hook/use-auth-event';
import { AuthContextProvider } from '~/modules/root/context';
import type { loader } from '~/routes/_app';
import type { GrowthBookSSRData } from '@growthbook/growthbook-react';
import { useGrowthBookSSR } from '@growthbook/growthbook-react';
import BookingModal from '@modules/enquiry/component/booking-modal/BookingModal';

const AppProviders = ({ children }: { children: React.ReactNode }) => {
    return (
        <ToasterProvider>
            <CartPopupProvider>{children}</CartPopupProvider>
        </ToasterProvider>
    );
};

export type AppData = SerializeFrom<typeof loader>;

export const links = () => [
    {
        rel: 'stylesheet',
        href: '/assets/website/styles/swiper@9.1.0/swiper-bundle.min.css',
    },
];

/**
 * renders top nav and footer around its children
 * It requires app loader to return data.
 * @param children
 * @param data
 * @constructor
 */
const AppLayout = ({
    children,
    data,
}: {
    children: JSX.Element;
    data: AppData;
}) => {
    const config = useConfigContext();
    const location = useLocation();
    const matches = useMatches();
    const cartPopupState = useCartPopupState();
    const { css } = useStyles();

    /**
     * we use useMatches here because we cannot call useLoaderData inside
     * Error boundaries. Since we are can't guarantee that we do have app data
     * we use the optional version
     */
    const {
        shoppingCartSummary,
        notifications,
        user,
        remixEnabled,
        ecommerceEnabled,
        backToTopEnabled,
        shopNavEnabled,
        searchPreFiliteringEnabled,
        footerCounterEnabled,
        toastMessage,
        imageConfigProps,
        monthlyUserCount,
        professionalBookingLink,
    } = data;

    const guestHomeEnabled = useABTestIsOn('guest-homepage');
    const isBMPreview = useMatchesHandleData('isBMPreview', false);

    const topNavigationData = {
        shoppingCart: shoppingCartSummary?.ShoppingCart,
        notifications,
        user,
    };

    const {
        env,
        config: { webpush },
        growthbookContext,
    } = useRootData();

    const tracker = useTracker();

    useGrowthBookSSR(growthbookContext as GrowthBookSSRData);

    useOneTapSignIn(config.google.otsId, isBMPreview);

    // Fires page view events
    useLogPageView(location, tracker, (state) =>
        makePageViewTrackingData(state)
    );

    // Fire website referral & download tracking events
    useLogLinkClicks(location, tracker);

    useEnquirySession(
        {
            canHandleAutoSubmit: true,
        },
        undefined,
        tracker
    );

    useAuthEvent(tracker);

    useOnObjectChange(
        {
            hashKey: 'feature-flags',
            value: {
                user: {
                    id: user.ID,
                    type: user.__typename,
                },
                features: user.FeatureFlags.map(({ Name, Enabled }) => {
                    return { Name, Enabled };
                }),
            },
            callback: () => {
                window.location.reload();
            },
        },
        [user]
    );

    const { topNavigation, footerNavigation } = useHideNavigation(matches);
    const isLoggedIn = user.__typename === 'Me';
    const isHomePage = matches.every((match) => match.pathname === '/');
    const showGuestNav = guestHomeEnabled && isHomePage && !isLoggedIn;

    const { toast, enquiryToastClickHere } = useToaster();

    React.useEffect(() => {
        if (!toastMessage) return;
        toast(toastMessage.message, {
            type: toastMessage.type,
            ...(toastMessage.enquiryClickLink && {
                clickHereLink: enquiryToastClickHere, // For enquiry toasts
            }),
        });
    }, [toastMessage, toast, enquiryToastClickHere]);

    const shouldReload = useDeploymentShouldReload({
        idleTimeout: 60e3,
        clientVersion: env.version,
        host: env.host,
        getServerVersion,
        config: webpush,
    });
    const navigation = useNavigation();
    const shouldReloadRef = useLatest(shouldReload);

    React.useEffect(() => {
        if (navigation.state === 'loading' && shouldReloadRef.current()) {
            window.location.replace(
                `${navigation.location.pathname}${navigation.location.search}`
            );
        }
    }, [navigation, shouldReloadRef]);

    return (
        <ImageConfigProvider value={imageConfig(imageConfigProps)}>
            <AuthContextProvider>
                <SaveToDesignBoardProvider>
                    <Flex column className={css(S.PageBase)}>
                        {topNavigation && (
                            <TopNavigation
                                //#techdebt: this is a hack to appease the type check gods
                                data={topNavigationData as TopNavigationData}
                                cartPopupState={cartPopupState}
                                remixEnabled={remixEnabled}
                                ecommerceEnabled={ecommerceEnabled}
                                backToTopEnabled={backToTopEnabled}
                                shopNavEnabled={shopNavEnabled}
                                searchPreFiliteringEnabled={
                                    searchPreFiliteringEnabled
                                }
                                showGuestNav={showGuestNav}
                            />
                        )}
                        <div className={css(S.PageContent)}>{children}</div>
                        {footerNavigation && (
                            <FooterNavigation
                                remixEnabled={remixEnabled}
                                showGuestNav={showGuestNav}
                                displayUserCounter={footerCounterEnabled}
                                monthlyUserCount={monthlyUserCount}
                            />
                        )}
                    </Flex>
                    <DesignBoardInitializer />
                    <CountrySuggestModal />
                    <SiteWideModals />
                    {professionalBookingLink && (
                        <BookingModal
                            teamBookingSegment={professionalBookingLink}
                            initialOpen={true}
                        />
                    )}
                </SaveToDesignBoardProvider>
            </AuthContextProvider>
        </ImageConfigProvider>
    );
};

export const ErrorBoundary = () => {
    const data = useMaybeAppData();
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        if (data) {
            return (
                <AppProviders>
                    <AppLayout data={data}>
                        <CatchPage caught={error} />
                    </AppLayout>
                </AppProviders>
            );
        }
        return (
            <AppProviders>
                <CatchPage caught={error} />
            </AppProviders>
        );
    }

    let errorObj = new Error('Unknown error');
    if (error instanceof Error) {
        errorObj = error;
    }

    return (
        <AppProviders>
            <ServerErrorPage error={errorObj} />
        </AppProviders>
    );
};

const AppPage = () => {
    const appData = useAppData();

    return (
        <AppProviders>
            <AppLayout data={appData}>
                <Outlet />
            </AppLayout>
        </AppProviders>
    );
};

export default AppPage;
