import {
    AppDisplayModeType,
    LogoAnimation,
    TopNavigationProps,
    disableBodyScroll,
    enableBodyScroll,
} from '@archipro-design/aria';
import {
    useBoolean,
    useClickAway,
    useIsomorphicLayoutEffect,
    useLatest,
    usePrevious,
} from 'ahooks';
import {
    Dispatch,
    RefObject,
    SetStateAction,
    useEffect,
    useRef,
    useState,
    SyntheticEvent,
} from 'react';
import { searchBarSlot } from '../slots/searchBarSlot';
import { searchMenuSlot } from '../slots/searchMenuSlot';
import { searchSlot } from '../slots/searchSlot';
import {
    Directory,
    onSearchClick,
    onSearchNavigate,
    onSearchType,
    RenderAnchorTag,
    SearchNavResults,
    TopNavSearchItem,
} from '../types';
import { useRecentlySearched } from './useRecentlySearched';
import { Location } from 'history';
import { getCategoryFromUrl } from '../utils/getCategoryFromUrl';
import { useTracker } from '@archipro-website/tracker';
import { MegaMenuState } from './useMegaMenu';
import { Actions } from 'ahooks/lib/useBoolean';
import { renderAnchorTag as defaultRenderAnchorTag } from '../utils/renderAnchorTag';
import { useTrackerOnUrlParamSearchChanges } from './useTrackerOnUrlParamSearchChanges';

type SearchProps = Pick<
    TopNavigationProps,
    | 'showSearchBar'
    | 'searchBar'
    | 'showSearchMenu'
    | 'searchMenu'
    | 'searchSlot'
> & {
    showBackdropSearch?: boolean;
    setShowBackdropSearch: Actions;
};

interface UseSearchPropsOpts {
    location: Location;

    currentSearch?: string;
    searchData?: SearchNavResults | null;

    showSearchBarState: ReturnType<typeof useBoolean>;
    onSearchClick: onSearchClick;
    /** AKA the onChange on the input */
    onSearchType: onSearchType;
    /** Navigation event. Used to redirect the page */
    onSearchNavigate: onSearchNavigate;

    setLogoAnimation: Dispatch<SetStateAction<LogoAnimation | undefined>>;
    setMegaMenuState: Dispatch<SetStateAction<MegaMenuState | undefined>>;
    showSearchBarAction: Actions;
    showFullLogoActions: Actions;
    expandedMenuActions: Actions;
    defaultFullLogo: boolean;
    setDirectory: (directory: Directory | null) => void;

    renderAnchorTag: RenderAnchorTag;
    appDisplayMode: AppDisplayModeType;
    showPersonalMenu: boolean;
    topNavRef: RefObject<HTMLElement>;
    appType?: 'web' | 'manager';
    searchCategory: Directory | null;
}

export function useSearchProps({
    location,

    currentSearch = '',
    searchData,
    showSearchBarState,
    onSearchClick,
    onSearchType,
    onSearchNavigate,

    setLogoAnimation,
    setMegaMenuState,
    showSearchBarAction,
    showFullLogoActions,
    expandedMenuActions,
    defaultFullLogo,
    setDirectory,

    renderAnchorTag,
    appDisplayMode,
    showPersonalMenu,
    topNavRef,
    appType,
    searchCategory,
}: UseSearchPropsOpts): SearchProps {
    const [showSearchBar, searchBarActions] = showSearchBarState;
    const { ESINDEX } = getCategoryFromUrl(location);
    const [searchValue, setSearchValue] = useState<TopNavSearchItem>({
        search: currentSearch,
        category: ESINDEX,
    });
    const urlSearchParams = useURLSearchParams(location);
    const lastUniqueURL = useLastUniqueURL(location);

    const tracker = useTracker();

    useTrackerOnUrlParamSearchChanges(urlSearchParams, tracker);

    useHandleNavigatingAwayFromSearchPage(location, () =>
        setSearchValue({ search: '' })
    );

    const [showSearchMenu, searchMenuActions] = useBoolean(false);
    const [showBackdropSearch, setShowBackdropSearch] = useBoolean(false);

    useEffect(() => {
        if (appDisplayMode.mobile) {
            return;
        }
        const topNav = topNavRef.current;
        if (!topNav) {
            return;
        }
        if (showBackdropSearch) {
            disableBodyScroll(topNav);
        }
        if (!showBackdropSearch) {
            enableBodyScroll(topNav);
        }
        return () => {
            enableBodyScroll(topNav);
        };
    }, [showBackdropSearch, appDisplayMode]);

    const recentlySearched = useRecentlySearched();

    const searchBarRef = useRef<HTMLDivElement>(null);
    const searchMenuRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const isURLActive = urlSearchParams.get(PARAM_SEARCH_ACTIVE_KEY) === '1';

    useEffect(() => {
        searchMenuActions.set(isURLActive);
    }, [isURLActive, searchMenuActions]);
    // Close menu when clicking out of search
    useClickAway(() => {
        if (!appDisplayMode.desktop) {
            return;
        }
        searchMenuActions.setFalse();
        searchBarActions.setFalse();
        setShowBackdropSearch.setFalse();
    }, [topNavRef]);

    useEffect(() => {
        if (!searchInputRef.current || !showSearchBar) {
            return;
        }
        const searchInput = searchInputRef.current;
        searchInput.focus();
        searchInput.selectionStart = searchInput.selectionEnd =
            searchInput.value.length;
    }, [urlSearchParams, searchInputRef, showSearchBar]);

    const showSearchMenuProp =
        (showSearchMenu && !showPersonalMenu) ||
        (showSearchMenu && !!searchData?.all?.length);

    return {
        showBackdropSearch, // Not an Aria prop
        setShowBackdropSearch,
        searchSlot: searchSlot(
            appType === 'manager' ? defaultRenderAnchorTag : renderAnchorTag,
            appType === 'manager'
                ? '/search?searchActive=1&ref=' + location.pathname
                : '/search',
            (e: SyntheticEvent) => {
                if (appDisplayMode.mobile || appType !== 'web') {
                    return;
                }

                // Disable button from pushing us to the search page
                e.preventDefault();
                // Propagation is bein picked up elsewhere
                // causing the useClickAway to fire after clicking it.
                // This is only happening on manager
                e.stopPropagation();

                searchBarActions.toggle();

                setDirectory(null);
                if (defaultFullLogo) {
                    showFullLogoActions.setTrue();
                } else {
                    showFullLogoActions.toggle();
                }
                setLogoAnimation(undefined);
                setMegaMenuState(undefined);
            }
        ),
        showSearchMenu: showSearchMenuProp,
        searchMenu: searchMenuSlot({
            ref: searchMenuRef,
            search: searchValue.search,
            searchData,
            recentlySearched,
            onSearchClick(event, item) {
                onSearchClick(event, item);
                if (item?.categoryLink) {
                    setSearchValue({ search: '' });
                } else {
                    item && setSearchValue(item);
                }
                if (appDisplayMode.desktop) {
                    searchBarActions.setFalse();
                } else {
                    expandedMenuActions.setFalse();
                }

                searchMenuActions.setFalse();
                setShowBackdropSearch.setFalse();
            },
            appDisplayMode,
        }),
        showSearchBar: !appDisplayMode.mobile
            ? showSearchBar
            : isSearchPageActive(location) &&
              !urlSearchParams.get(PARAM_SEARCH_QUERY_KEY) &&
              !showPersonalMenu,
        // patch for mobile, TODO once we have design for mobile
        searchBar: searchBarSlot({
            ref: searchBarRef,
            searchValue: searchValue.search,
            inputRef: searchInputRef,
            onChange(event, data) {
                if (!data) {
                    return;
                }
                const { item, type } = data;
                item.category = searchCategory
                    ? `${searchCategory}s`
                    : undefined;
                setSearchValue(item);
                switch (type) {
                    case 'searchType':
                        onSearchType(event, item.search);
                        searchMenuActions.setTrue();
                        setShowBackdropSearch.setTrue();
                        break;
                    case 'searchClick':
                        recentlySearched.addRecentSearch(searchValue.search);
                        searchMenuActions.setFalse();
                        setShowBackdropSearch.setFalse();
                        onSearchClick(event, item);
                        searchInputRef.current?.blur();
                        break;
                    default:
                        break;
                }
            },
            onClose(e) {
                if (appDisplayMode.mobile) {
                    setSearchValue({ search: '' });
                    return;
                }

                if (searchValue.search) {
                    setSearchValue({ search: '' });
                } else {
                    searchBarActions.setFalse();
                    searchMenuActions.setFalse();
                    setShowBackdropSearch.setFalse();
                }
                setMegaMenuState(undefined);
                setLogoAnimation(undefined);
                if (defaultFullLogo) {
                    showFullLogoActions.setTrue();
                } else {
                    showFullLogoActions.toggle();
                }
                onSearchNavigate(lastUniqueURL, 'push');
                e.stopPropagation();
            },
            onCancel(e) {
                if (appDisplayMode.mobile) {
                    setSearchValue({ search: '' });
                    searchMenuActions.setFalse();
                    return;
                }

                setSearchValue({ search: '' });
                searchBarActions.setTrue();
                searchMenuActions.setFalse();
                setShowBackdropSearch.setFalse();
                e.stopPropagation();

                // lastUniqueURL
                onSearchNavigate(
                    `${location.pathname}?${urlSearchParams.toString()}`,
                    'replace'
                );
            },
            onFocus() {
                if (appDisplayMode.desktop) {
                    setDirectory(null);
                    setShowBackdropSearch.setTrue();
                    const searchInput = searchInputRef.current;
                    if (searchInput) {
                        searchInput.focus();
                        searchInput.selectionStart = searchInput.selectionEnd =
                            searchInput.value.length;
                    }
                    if (!showSearchBar) {
                        showSearchBarAction.setTrue();
                        searchMenuActions.setTrue();
                        tracker.log('OpenSearch', {
                            url: new URL(window.location.href),
                        });
                    }
                } else {
                    // mobile
                    if (!showSearchMenu) {
                        searchMenuActions.setTrue();
                        tracker.log('OpenSearch', {
                            url: new URL(window.location.href),
                        });
                    }
                }

                // navigate to remix when user searching from legacy route
                if (appType === 'manager' && typeof window !== 'undefined') {
                    window.location.href = '/search';
                }
            },
            appDisplayMode,
            location,
            showSearchBar,
            showSearchMenu: showSearchMenuProp,
        }),
    };
}

export const PARAM_SEARCH_QUERY_KEY = 'search';
export const PARAM_SEARCH_ACTIVE_KEY = 'searchActive';

function useHandleNavigatingAwayFromSearchPage(
    location: Location,
    clearInput: () => void
) {
    const isOnSearchPage = isSearchPageActive(location);
    const previousIsSearchPage = usePrevious(isOnSearchPage);
    const stableClearInput = useLatest(clearInput);
    useIsomorphicLayoutEffect(() => {
        const navigatedAwayFromSearch = previousIsSearchPage && !isOnSearchPage;
        if (navigatedAwayFromSearch) {
            stableClearInput.current();
        }
    }, [stableClearInput, isOnSearchPage, location, previousIsSearchPage]);
}

export function isSearchPageActive(location: Location) {
    return location.pathname.startsWith('/search');
}

export const useLastUniqueURL = (location: Location): string => {
    const history = useRef<string>('');

    const currentLocation = location.pathname;
    const previousLocation = usePrevious(currentLocation);

    const isNew = currentLocation !== previousLocation;
    if (
        isNew &&
        previousLocation !== undefined &&
        history.current !== previousLocation &&
        !previousLocation.startsWith('/search')
    ) {
        history.current = previousLocation;
    }

    return history.current;
};

function useURLSearchParams(location: Location) {
    const prevLocation = usePrevious(location);
    const [urlParams, setUrlParams] = useState(
        new URLSearchParams(location.search)
    );
    useEffect(() => {
        if (location !== prevLocation) {
            setUrlParams(new URLSearchParams(location.search));
        }
    }, [location, prevLocation]);
    return urlParams;
}
